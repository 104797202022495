import React from 'react'
import './TestimonialCard.css'
import userIcon from '../../../media/user_icon.png'

function TestimonialCards({name, company, review}) {
  return (
    <div className="card testimonial_cards" id="card1">
      <div className="user">
        <img src={userIcon} alt="user profile pic" width={'100px'} height={'100px'} />
        <div className="user-info">
          <h2>{name}</h2>
        </div>
      </div>

      <div className="content">
        <h1>{company}</h1>
        <p>
          {review}
        </p>
      </div>
    </div>
  );
}

export default TestimonialCards