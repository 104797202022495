import React from "react";

function SectionCard(props) {
  return (
    <div
      className={`${props.position} ${props.height} ${props.width} ${props.backgroundColor} ${props.color} ${props.visible}`}
    >
      {props.children}
    </div>
  );
}

export default SectionCard;
