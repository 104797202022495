import React from "react";
import "./LMSPageContent.css";
import LmsHeroImg from "../../media/lms_hero_image.webp";
import LmsFlow from "../../media/lms_flow.webp";
import why_choose_nbb_lms from "../../media/why_choose_nbb_lms.webp";
import FaqSectionComponent from "../CommonComponents/FaqSectionComponent/FaqSectionComponent";
import CtaComponent from "../CommonComponents/CtaComponent/CtaComponent";
import TestimonialCards from "../CommonComponents/Cards/TestimonialCards";
import SectionCard from "../CommonComponents/SectionCard";
import ContactComponent from "../CommonComponents/ContactComponent/ContactComponent";

function LMSPageContent() {
  const DemoCtaText =
    "We transform your dream of streamlining your business online into reality. Let’s dive into the world of IT and digital marketing solutions with our expertise.";
  const faqData = [
    {
      q: "What is a loan management system? ",
      a: "It’s an advanced and state-of-the-art service that has multiple features to accelerate the growth rate of your business. It can help you capture the lead, and initiate the required services associated with digital lending.",
    },
    {
      q: "What are the processes involved in the loan management system?",
      a: "Starting from the lead capturing, it can be used to track the entire process during the loan system. ",
    },
    {
      q: "How can it cultivate my business?",
      a: "By associating with the loan management system, which is advanced, the efficiency of your business gets enhanced where the lead generation ratio becomes higher.  And once it gets higher the revenue generation becomes higher.  ",
    },
    {
      q: "How much time would it take to be customized from your side?",
      a: "It will depend on the size and your lending capacity where there are multiple formalities and guidelines. And considering all these, it will completely depend on your requirements.",
    },
    {
      q: "What are the features and benefits of a loan management system?",
      a: "It has multiple beneficial features like automation, speed, and transparency, and through which your lending process becomes easy because it can be handled by the team easily.   ",
    },
  ];
  const faqHeading =
    "Indeed, you might have lots of questions about the loan management system that how it could cultivate your business. Therefore, some essential questions are there which are frequently asked by our esteemed customers and clients.";

  // window width
  let scrollValue = 0;
  const windowWidth = window.innerWidth;
  if (windowWidth > 500) {
    scrollValue = 3720;
  } else {
    scrollValue = 5400;
  }

  return (
    <div>
      <div className="lms_main_page_content">
        <section className="LMSHero_section">
          <div className="left">
            <img src={LmsHeroImg} alt="img" width={"500px"} />
          </div>
          <div className="right">
            <h1>Transform Your Lending System with Automation</h1>
            <p className="">
              Choose a Robust Loan Management System that can Enhance Your
              Services and Productivity.
            </p>
            <main>
              <ul className="cards-container">
                <li className="cyan-feature-card">
                  <h2>Lead Management</h2>
                  <p>Track and nurture the lead with relevant services.</p>
                </li>

                <li className="red-feature-card">
                  <h2>API Integration</h2>
                  <p>Integrate with different Apps for communication.</p>
                </li>

                <li className="orange-feature-card">
                  <h2>Reporting</h2>
                  <p>Evaluate the leads continuously for better facilities.</p>
                </li>

                <li className="blue-feature-card">
                  <h2>Tech Support</h2>
                  <p>24x7 tech support for better assistance is available.</p>
                </li>
              </ul>
            </main>
          </div>
        </section>
        <section className="lms_Featured">
          <div className="upper">
            <h1>
              Upgrade and Cultivate Your Lending Services with Our Customized
              Loan Management System
            </h1>
            <p>
              Experience a transformative leap in your loan operations using our
              state-of-the-art Loan Management System. Streamline processes,
              enhance efficiency, and ensure compliance while offering borrowers
              a seamless experience. Elevate your lending capabilities with
              comprehensive tools and analytics for better decision-making and
              customer satisfaction
            </p>
            <img className="mt-4" src={LmsFlow} alt="img" />
          </div>
          <div className="lower"></div>
        </section>
        <section className="lms_choose_us">
          <div className="upper">
            <h1>Why Choose NextBigBox LMS Over others</h1>
            <p>
              We have created a transformative loan management system
              considering all the requirements of lenders and borrowers as well.
              It has the efficiency and potential to impart the best experience
              to borrowers and to streamline the lending process with the
              complete digital form.
            </p>
            <p></p>

            <ul className="my-feature">
              <ul>
                <li>Seamless Efficiency</li>
                <li>Comprehensive Features</li>
                <li>Customization</li>
              </ul>
              <ul>
                <li>Data Security</li>
                <li>Analytics and Insights</li>
                <li>Exceptional Support</li>
              </ul>
            </ul>
          </div>
          <div className="bottom" data-aos="zoom-in-up">
            <img src={why_choose_nbb_lms} alt="img" />
          </div>
        </section>
      </div>
      <CtaComponent
        description={DemoCtaText}
        scrollVal={scrollValue}
        scrollDuration={100}
      />
      <div className="lms_main_page_content lms_main_page_content_middle">
        <section className="feature_section_main">
          <div className="left">
            <h1>Don't take our words</h1>
            <p style={{ paddingRight: "4rem", marginTop: "1rem" }}>
              There are enormous stories from our clients where after getting
              services from our side they were able to transform their business
              with our loan management system. In their world, they have been
              performing well in the business and ensuring revenue growth. Our
              loan management system has been functioning for different
              functions in the lending process like.
            </p>
            <ul className="list-item">
              <li>
                <p>1.</p>
                <span>Receiving the fresh leads. </span>
              </li>
              <li>
                <p>2.</p>
                <span>Nurture the leads.</span>
              </li>
              <li>
                <p>3.</p>
                <span>Conversion of leads.</span>
              </li>
              <li>
                <p>4.</p>
                <span>Facilitate the lending services.</span>
              </li>
              <li>
                <p>5.</p>
                <span>Smoothly Accomplish the operation.</span>
              </li>
            </ul>
          </div>
          <div className="right feature_section2">
            <div className="wrapper2">
              <ul className="ticker-list2">
                <li>
                  <TestimonialCards
                    name={"Akshita Mathur"}
                    company={"Google Review"}
                    review={
                      "This company specializes in effectively launching your business on digital platforms. It is the besst Digital marketing agency to uplift your business."
                    }
                  />
                </li>
                <li>
                  <TestimonialCards
                    name={"Neha Kumari"}
                    company={"Google Review"}
                    review={
                      "Great digital marketing company I took the google ads services and got good results for now. So I would like to refer to all my friends and family."
                    }
                  />
                </li>
                <li>
                  <TestimonialCards
                    name={"Sanil Rao"}
                    company={"Google Review"}
                    review={
                      "Extremely delighted by the overall effort of Hitesh and Sucheta. Together they have ensured the average cost per lead is reduced and total number of leads have increased. Both were very prompt and"
                    }
                  />
                </li>
                <li>
                  <TestimonialCards
                    name={"Anupam Mukharjee"}
                    company={"Google Review"}
                    review={
                      "Get Great Prices, Excellent performance in SEO service and website maintenance. NEXTBIGBOX is a SEO and digital marketing Specialists company"
                    }
                  />
                </li>
                <li>
                  <TestimonialCards
                    name={"Shubham"}
                    company={"Google Review"}
                    review={
                      "Mann....the experience I got while rebuilding my website by them...It was just awesome"
                    }
                  />
                </li>
                <li>
                  <TestimonialCards
                    name={"Rohit Sharma"}
                    company={"Google Review"}
                    review={"One of the best company satisfied"}
                  />
                </li>
                <li>
                  <TestimonialCards
                    name={"Nitin Kumar"}
                    company={"Google Review"}
                    review={
                      "Their strategies produced remarkable results for my business. From SEO to social media, they demonstrated an impressive understanding of the digital landscape. Their team's creativity, prompt communication, and data-driven approach truly set them apart. Highly recommend!"
                    }
                  />
                </li>
                <li>
                  <TestimonialCards
                    name={"satish gosavi"}
                    company={"Google Review"}
                    review={
                      "I am using the services of Nextbigbox for last 2 years now. They are always attentive for all queries. I will recommend the services of Nextbigbox for SEO, PPC, Digital Marketing all "
                    }
                  />
                </li>
              </ul>
              <ul className="ticker-list3">
                <li>
                  <TestimonialCards
                    name={"Sanil Rao"}
                    company={"Google Review"}
                    review={
                      "Extremely delighted by the overall effort of Hitesh and Sucheta. Together they have ensured the average cost per lead is reduced and total number of leads have increased. Both were very prompt and accommodating across all the request."
                    }
                  />
                </li>
                <li>
                  <TestimonialCards
                    name={"Navodit Tyagi"}
                    company={"Google Review"}
                    review={
                      "Next Big Box is an outstanding website development firm that exceeded my expectations. Hitesh and Deepika, the team members assigned to my project, showcased remarkable expertise and creativity. Hitesh's technical prowess and attention to "
                    }
                  />
                </li>
                <li>
                  <TestimonialCards
                    name={"Payal Singhal"}
                    company={"Google Review"}
                    review={
                      "Outstanding SEO work for CSL Finance Ltd and marathon digital campaign! for CSL Foundation Our website's visibility has skyrocketed, thanks to your expert strategies and implementation. Your SEO expertise and marathon digital campaign have "
                    }
                  />
                </li>
                <li>
                  <TestimonialCards
                    name={"BRAJESH PANDEY"}
                    company={"Google Review"}
                    review={
                      "Had a wonderful service experience. It's an amazing company with well equipped work force."
                    }
                  />
                </li>
                <li>
                  <TestimonialCards
                    name={"Anupam Mukharjee"}
                    company={"Google Review"}
                    review={
                      "Get Great Prices, Excellent performance in SEO service and website  maintenance. NEXTBIGBOX is a SEO and digital marketing Specialists company"
                    }
                  />
                </li>
                <li>
                  <TestimonialCards
                    name={"Rahuk Kumar"}
                    company={"Google Review"}
                    review={
                      "Next big box is very good in digital media or paid advertising specialist in real estate lead generation and branding solution."
                    }
                  />
                </li>
                <li>
                  <TestimonialCards
                    name={"Nitin Kumar"}
                    company={"Google Review"}
                    review={
                      "Their strategies produced remarkable results for my business. From SEO to social media, they demonstrated an impressive understanding of the digital landscape. Their team's creativity, prompt communication, and data-driven approach truly set them apart. Highly recommend!"
                    }
                  />
                </li>
                <li>
                  <TestimonialCards
                    name={"Neha Kumari"}
                    company={"Google Review"}
                    review={
                      "Great digital marketing company I took the google ads services and got good results for now. So I would like to refer to all my friends and family."
                    }
                  />
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="lms_faq">
          <FaqSectionComponent faqHeading={faqHeading} faqData={faqData} />
        </section>
      </div>
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
      >
        <ContactComponent />
      </SectionCard>
    </div>
  );
}

export default LMSPageContent;
