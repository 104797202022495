// {
//   place: "London",
//   h1: "Rank higher with the Best Digital Marketing Agencies in London",
//   h1_paragraph: (
//     <>
//       <p className="mt-12 text-justify"></p>
//       <ul className="list-disc list-inside mt-10 text-justify">
//         <li className="m-2">
//           <b> </b>
//         </li>
//         <li className="m-2">
//           <b> </b>
//         </li>
//       </ul>
//       <p className="mt-10 text-justify"></p>
//     </>
//   ),
//   h2_paragraph_1: (
//     <>
//       <h2 className="mt-10 lg:mt-0 font-bold text-[1.8rem] text-center"></h2>
//       <p className="mt-4 text-justify"></p>
//       <p className="mt-10"></p>
//     </>
//   ),
//   h2_paragraph_2: (
//     <>
//       <h2 className="mt-10 lg:mt-0 font-bold text-[1.8rem] text-center"></h2>
//       <p className="mt-4 text-justify"></p>
//       <p className="mt-10 text-justify"></p>
//     </>
//   ),
//   h3_paragraph: "",
//   h3_list: [],
//   faqHeading: "",
//   faqData: "",
//   metaTitle: "",
//   metaDescription:
//     "",
//   metaKeywords: [
//
//   ],
//   canoncialTags:
//     "https://www.nextbigbox.io/digital-marketing-servies-in-india",
//   geoTargetCode: [
//     {
//       name: "DC.title",
//       content: "",
//     },
//     {
//       name: "geo.region",
//       content: "",
//     },
//     {
//       name: "geo.placename",
//       content: "",
//     },
//     {
//       name: "geo.position",
//       content: "",
//     },
//     {
//       name: "ICBM",
//       content: "",
//     },
//   ],
// },
import img1 from "../media/LocationPageImages/Singapore/1.jpg";
import img2 from "../media/LocationPageImages/Singapore/2.jpg";
import img3 from "../media/LocationPageImages/Singapore/3.jpg";
import img4 from "../media/LocationPageImages/Singapore/4.jpg";

const singaporeList = [
  {
    place: "singapore",
    h1: "The Best Digital Marketing Agency in Singapore",
    h1_paragraph: (
      <>
        <p className="mt-12 text-justify">
          In search of the <b>Best digital marketing agency in Singapore</b>? We
          at NextBigBox are proud to offer the best result-driven techniques for
          your online growth. Being a leading <b>SEO agency in Singapore</b>, we
          tend to provide a wide range of services aimed at enhancing your
          brand’s visibility.{" "}
        </p>
        <ul className="list-disc list-inside mt-10 text-justify">
          <li className="m-2">
            We drive considerable traffic to your website and boost the search
            engine rankings.
          </li>
          <li className="m-2">Our expertise in SEO helps us to stand out.</li>
          <li className="m-2">Our expertise in SEO helps us to stand out.</li>
        </ul>
        <p className="mt-10 text-justify">
          Our approaches are crafted in a way that ensures converting visitors
          into potential customers. We offer the best customised marketing
          solutions that serve you a measurable success. So, if you also want to
          experience the difference and want more quality leads, do not delay in
          connecting with us.
        </p>
      </>
    ),
    h2_paragraph_1: (
      <>
        <h2 className="mt-10 lg:mt-0 font-bold text-[1.8rem] text-center">
          Your Top Digital Marketing Agency Partner
        </h2>
        <p className="mt-4 text-justify">
          At NextBogBox, we create the best digital marketing campaigns,
          ensuring to fulfil your business objectives and target the right
          audience. We enhance brand awareness effectively across the various
          industries. As a trusted <b>SEO agency in Singapore</b>, we consider
          enhancing conversions and generating quality leads. We have strategic
          approaches to elevate your digital presence and get your business to
          new heights.
        </p>
        <p className="mt-10">
          Also, our robust experience in delivering innovative marketing
          solutions customised to your online store’s requirements helps us to
          get the best outcomes. Hence, partner with us to see the outstanding
          change and lead the online market by staying ahead.
        </p>
      </>
    ),
    h2_paragraph_2: (
      <>
        <h2 className="mt-10 lg:mt-0 font-bold text-[1.8rem] text-center">
          Creative Digital Marketing Agency in Singapore
        </h2>
        <p className="mt-4 text-justify">
          As an experienced <b>SEO agency in Singapore</b>, we have a clear
          understanding of the uniqueness of every project, which has to be
          handled in its specific way. Our mission is to let the audience know
          your inspiring brand story through promoting your products and
          services online. We are proud of our team of innovative thinkers who
          possess expertise in a vast range of services. Our campaigns are not
          visually aesthetic but drive measurable results and serve the success.
        </p>
        <p className="mt-10 text-justify">
          We have the best understanding of the evolving online market by
          keeping up with the trends and offering suitable solutions.
        </p>
      </>
    ),
    h3_paragraph: (
      <>
        <h2 className="text-center mt-20 lg:mt-40 text-[1.8rem] font-bold">
          Best Digital Marketing Services in Singapore
        </h2>
        <p className="text-center mt-4">
          We open your brand’s potential by providing the{" "}
          <b>Best digital marketing services in Singapore</b>. Let’s review the
          range of services we offer:
        </p>
      </>
    ),
    h3_list: [
      {
        title: "Search Engine Optimization (SEO)",
        content: (
          <>
            Boost your online appearance in search results with our exclusive
            search engine optimization services. Being the{" "}
            <b>Best SEO agency in Singapore</b>, we use our effective SEO
            strategies to perfectly optimise each element of your website and
            make it rank higher. Also, we attract organic traffic and boost user
            experience, which helps us to enhance your business growth.
          </>
        ),
      },
      {
        title: "Social Media Optimization (SMO)",
        content: (
          <>
            Boost your social media followers through our effective social media
            optimization service and drive targeted traffic to your website. We
            foster community and your brand’s online presence through valuable
            approaches. Also, we keep monitoring the activities on your account
            to refine strategies accordingly.
          </>
        ),
      },
      {
        title: "Pay Per Click (PPC)",
        content: (
          <>
            PPC campaigns are very effective in driving immediate traffic and
            generating quality leads. PPC strategies include keyword bidding,
            compelling ad copies, and so on that help in targeting specific
            audiences. Moreover, continuous monitoring helps in improving
            campaigns and maximise ROI by enhancing online visibility.
          </>
        ),
      },
      {
        title: "Branding",
        content: (
          <>
            Branding is the most beneficial service we offer in Singapore, as it
            helps in establishing a strong identity that your target audience
            feels connected with. We design the best logos that look different
            and create a unique identity for your business. It helps in building
            trust and long-term relationships.
          </>
        ),
      },
      {
        title: "Social Media Marketing (SMM)",
        content: (
          <>
            Leverage the power of social media platforms with our best{" "}
            <b>Social media marketing services in Singapore</b>. We help you to
            feel connected with your audience and promote your services or
            products efficiently. Social media is the growing platform that lets
            you create engaging and shareable content that attracts the new
            audience.
          </>
        ),
      },
      {
        title: "Content Marketing",
        content: (
          <>
            With our valuable <b>Content marketing services in Singapore</b>, we
            tend to deliver informative and quality content and attract
            audiences. We share in-depth information about the products and
            services you sell online to address customers’s queries and turn
            them into loyal customers. So, boost your conversion and foster
            trust.
          </>
        ),
      },
      {
        title: "Web Design and Development",
        content: (
          <>
            Let us create a visually appealing and fast-running website through
            our exclusive <b>Website design agency in Singapore</b>. It
            efficiently enhances the user experience and makes them stay longer
            on your website. Being one of the best{" "}
            <b>Software development companies in Singapore</b>, we ensure that
            your website is performing well.
          </>
        ),
      },
      {
        title: "Local SEO Services",
        content: (
          <>
            Local SEO services are completely dedicated to driving local traffic
            to the website. It attracts the nearby audience and tries to convert
            them into loyal potential customers. With the correct tailored SEO
            strategies, we help you achieve business goals. So, drive more foot
            traffic and boost your customer base.
          </>
        ),
      },
      {
        title: "Ecommerce SEO Services",
        content: (
          <>
            Get more sales and with specialised e-commerce SEO strategies. As a
            well-organised website tends to attract more customers, we ensure
            the proper website optimization periodically. So, our experts ensure
            to let clients succeed in the digital marketing landscape while
            boosting onsite traffic. So, let’s grow your store by improving your
            site’s visibility.
          </>
        ),
      },
      {
        title: "Enterprise SEO Services",
        content: (
          <>
            Our enterprise SEO services are specially designed for large-scale
            businesses to address the unique challenges effectively. We help you
            improve your existing rankings and build new strategies that take
            your brand to new heights. Our professionals ease the complexity of
            SEO and drive considerable sales.
          </>
        ),
      },
      {
        title: "Email Marketing Services",
        content: (
          <>
            We offer personalised email marketing strategies to help you
            generate the revenue that your brand requires. The entire process
            aims to drive conversion and boost foot traffic. Through our best
            <b> Email marketing services in Singapore</b>, we help you reach
            your audience and keep engaging your subscribers.
          </>
        ),
      },
      {
        title: "LinkedIn Marketing Services",
        content: (
          <>
            Linking marketing services in Singapore help businesses reach their
            target audience through a professional network. Also, the customised
            ads and posts on LinkedIn promote businesses and their brands
            effectively. So, let us create the best advertising campaigns by
            choosing the specifics to meet your advertising needs.
          </>
        ),
      },
    ],
    faqHeading: "",
    faqData: [
      {
        q: " What are the services you offer in Singapore?",
        a: "We offer a vast range of quality digital marketing services, including SMM, SEO, content marketing, email marketing, Linkedin marketing, and so on. We aim to drive the measurable success of your business by boosting brand awareness.",
      },
      {
        q: "Is hiring a digital marketing agency a good decision?",
        a: "Excellent decision! A digital marketing agency can completely change the scenario of your business growth. We tailor the digital solutions that exactly suit your brand’s needs and increase the ROI.",
      },
      {
        q: "Are digital marketing services expensive?",
        a: "No, but it varies on the specific package or service you opt for. Though large-scale businesses may cost you more than small ones. So, it is better to discuss budgets with the agency.",
      },
      {
        q: "How long should I wait to see the digital marketing results?",
        a: "Having organic foot traffic takes more time, while PPC campaigns tend to show quicker results, even within a few weeks only. ",
      },
      {
        q: "What helps you to stand out from your competitors?",
        a: "We use the best digital approaches to get effective solutions. Our expert team mixes their expertise and years of knowledge to serve you the measurable outcomes. Also, we tend to adapt market changes to stay updated and make changes accordingly.",
      },
    ],
    metaTitle:
      "Best Digital Marketing Services Agency in Singapore - NextBigBox",
    metaDescription:
      "Grow your business with NextBigBox, the Best Digital Marketing Agency in Singapore. We offer SEO, PPC, SMM, Content Marketing Services and Web Development etc.",
    metaKeywords: [
      "best digital marketing agency in singapore",
      "top digital marketing services",
      "SEO services in singapore",
      "PPC marketing in singapore",
      "social media marketing singapore",
      "online marketing experts singapore",
      "digital marketing experts singapore",
    ],
    canonicalTag:
      "https://www.nextbigbox.io/digital-marketing-services-in-singapore",
    geoTargetCode: [
      {
        name: "DC.title",
        content:
          "Best Digital Marketing Services Agency in Singapore - NextBigBox",
      },
      {
        name: "geo.region",
        content: "SG",
      },
      {
        name: "geo.placename",
        content: "Singapore",
      },
      {
        name: "geo.position",
        content: "1.357107;103.819499",
      },
      {
        name: "ICBM",
        content: "1.357107, 103.819499",
      },
    ],
    scriptTag: {
      "@context": "https://schema.org/",
      "@type": "WebSite",
      name: "Nextbigbox",
      url: "https://www.nextbigbox.io/",
      potentialAction: {
        "@type": "SearchAction",
        target:
          "https://www.nextbigbox.io/digital-marketing-services-in-singapore?q={search_term_string}",
        "query-input": "required name=search_term_string",
      },
    },
    topDigitalMarketingImage: img1,
    bestDigitalMarketingImage: img2,
  },
  {
    place: "malaysia",
    h1: "The Best Digital Marketing Agency in Malaysia",
    h1_paragraph: (
      <>
        <p className="mt-12 text-justify">
          Tired of making efforts to transform your business’s online presence?
          At NextBigBox, we help prestigious brands unlock the real growth while
          navigating the complexities. Being the{" "}
          <b>Best digital marketing agency in Malaysia</b>, we focus on using
          result-driven digital marketing approaches. Our wide expert team
          ensures making every click count.
        </p>
        <ul className="list-disc list-inside mt-10 text-justify">
          <li className="m-2">
            We aim to redefine success by driving foot traffic to the website.
          </li>
          <li className="m-2">
            Our SEO strategies are proven to enhance your business’s online
            visibility.
          </li>
          <li className="m-2">
            Our professional team ensures efficiency to get measurable results.
          </li>
        </ul>
        <p className="mt-10 text-justify">
          We don’t make promises but show the outcomes. Our comprehensive suite
          of services is our specialty that helps us to set a new identity in
          the digital marketing industry. Partner with NextBigBox and ensure the
          best growth of your online business. We tailor our solutions to
          achieve your specific business goals.
        </p>
      </>
    ),
    h2_paragraph_1: (
      <>
        <h2 className="mt-10 lg:mt-0 font-bold text-[1.8rem] text-center">
          Elevate Brand’s Identity with Top Marketing Agency in Malaysia
        </h2>
        <p className="mt-4 text-justify">
          We have the best understanding of dealing with digital marketing
          challenges. We offer the best innovative services, ensuring that our
          digital marketing campaign and clicks contribute to your success.
        </p>
        <p className="mt-10">
          Let’s empower your brand, excel in the digital world, and increase
          your overall performance across search engine result pages (SERPs).
          Our experience helps us deliver the best solutions that bring not just
          short-term gains but sustainable growth for your brand. So, once you
          connect with us, get ready to turn your digital business into a strong
          asset.
        </p>
      </>
    ),
    h2_paragraph_2: (
      <>
        <h2 className="mt-10 lg:mt-0 font-bold text-[1.8rem] text-center">
          Your trusted digital marketing partner in Malaysia
        </h2>
        <p className="mt-4 text-justify">
          NextBigBox is the best <b>SEO agency in Malaysia</b>, aiming to bring
          expertise and creativity to take your brand to new heights. We closely
          listen to your unique business goals and offer tailored services.
        </p>
        <p className="mt-10 text-justify">
          We keep our team updated with the latest market trends and support a
          real-time tracking system to let you see the real health of your
          website. Our vision is to boost engagement and enhance conversion
          rates. Hence, let’s expand your digital presence and identity to gain
          the best profits.
        </p>
      </>
    ),
    h3_paragraph: (
      <>
        <h2 className="text-center mt-20 lg:mt-40 text-[1.8rem] font-bold">
          Best Digital Marketing Services Agency in Malaysia
        </h2>
        <p className="text-center mt-4">
          Here is an overview of our best range of digital marketing services in
          Malaysia. Let’s understand them better below:
        </p>
      </>
    ),
    h3_list: [
      {
        title: "Search Engine Optimization (SEO)",
        content: (
          <>
            Maximise your online visibility to the eternal with our tailored SEO
            strategies. We optimise keywords, helping you to rank higher on
            search engines. Our best techniques of upgrading on-page SEO and
            link building help you to drive organic traffic effectively. So,
            experience the growth and enhance the overall revenue.
          </>
        ),
      },
      {
        title: "Social Media Optimization (SMO)",
        content: (
          <>
            We focus on building your online reputation with our utmost valuable
            social media optimization services. We optimise your social media
            profiles to interact with online users and attract more potential
            clients. Our expert team will increase your brand awareness with the
            correct digital marketing strategy.
          </>
        ),
      },
      {
        title: "Pay Per Click (PPC)",
        content: (
          <>
            Pay Per Click is the trusted online advertising model that is known
            to bring immediate traffic to the website. We ensure managing your
            ad spend wisely by creating cost-effective ads. We ensure that our
            advertising campaigns are converting clicks into customers and
            providing the best return on investment.
          </>
        ),
      },
      {
        title: "Branding",
        content: (
          <>
            Let us build a memorable brand identity for your business that
            represents your brand story. Our best branding solutions help us
            deliver satisfied results to our clients. We design the best logo
            that conveys the right message to the audience. It will help your
            business to stand out in the digital landscape.
          </>
        ),
      },
      {
        title: "Social Media Marketing (SMM)",
        content: (
          <>
            We are the credible <b>Social media marketing agency in Malaysia</b>{" "}
            that ensures running targeted ads and making you interact with your
            audience. You can swiftly engage your audience and boost your brand
            loyalty with our unbeatable <b>Social media marketing services</b>.
            Our compelling content fosters your followers and exceeds
            visibility.
          </>
        ),
      },
      {
        title: "Content Marketing",
        content: (
          <>
            Our best content marketing team in Malaysia is committed to
            producing great content. We just do not focus on selling a product
            or service but invite your prospective customers. Our mission is to
            attract and retain customers through strong content marketing
            services. Also, we offer relevant content that addresses the
            customer's query.
          </>
        ),
      },
      {
        title: "Web Design and Development",
        content: (
          <>
            Our best web design and development services involve the process of
            creating an eye-catching website and improving the customer
            experience. We enhance the feel of a website that grabs the
            audience’s attention. Also, our specialised team determines that the
            website functions nicely. It helps you give your audience a
            user-friendly website.
          </>
        ),
      },
      {
        title: "Local SEO Services",
        content: (
          <>
            Boost your website’s local visibility and get the customers from
            your nearby places with our extremely beneficial local SEO
            strategies. Our professionals have expertise in website optimization
            to get measurable local search results. We use various digital
            marketing strategies to help you grow your business.
          </>
        ),
      },
      {
        title: "Ecommerce SEO Services",
        content: (
          <>
            Get our best eCommerce SEO solutions in Malaysia to extend sales of
            your online store. We also optimise the product pages using our
            expertise to drive traffic and increase conversions in your niche.
            Our proven SEO methods help you achieve online success. So,
            experience the organic searches now.
          </>
        ),
      },
      {
        title: "Enterprise SEO Services",
        content: (
          <>
            We are a reputed digital marketing agency in Malaysia that offers
            advanced SEO strategies to level up your business. Our scalable
            solutions help improve visibility and manage vast content. We make
            our clients ready to compete on a national and global scale.
          </>
        ),
      },
      {
        title: "Email Marketing Services",
        content: (
          <>
            Our best personalised email marketing services help you engage your
            customers directly. We ensure developing the best content and
            targeted emails to drive customer loyalty that enhances brand
            relationships. We leverage the best power possible of email
            marketing to boost conversion.
          </>
        ),
      },
      {
        title: "LinkedIn Marketing Services",
        content: (
          <>
            You can easily enhance your professional presence and get introduced
            to industry leaders on Linkedin. With our best Linkedin marketing
            services, we focus on building authority and generating leads. Also,
            we boost the engagement in the B2B place to serve extra profit to
            our clients.
          </>
        ),
      },
    ],
    faqHeading: "",
    faqData: [
      {
        q: "What services does your digital marketing agency provide",
        a: "We are proud of our range of digital marketing services. For instance, SEO, PPC, social media marketing, content marketing, web design, branding, and specialised services like local and eCommerce SEO.",
      },
      {
        q: "How long does it take to see results from SEO?",
        a: "SEO results vary by industry and competition but generally take 3 to 6 months for noticeable improvements. Our team provides regular updates and monitors performance closely to ensure progress.",
      },
      {
        q: "Why should I choose your agency over others?",
        a: "We prioritise personalised strategies, measurable results, and best customer support. Our local expertise in Malaysia, combined with global best practices. It helps your business reach its digital marketing goals.",
      },
      {
        q: "Do you work with small businesses as well as large enterprises?",
        a: "Yes! We work with businesses of all sizes. We have expertise to deal with the complexities that small businesses usually face. Also, we have extensive experience in handling vast pages of enterprises.",
      },
      {
        q: "Can you help with improving my social media presence?",
        a: "Yes! We provide social media optimization, content creation, and targeted ad campaigns on major platforms. It enhances your brand’s reach and engagement with your target audience.",
      },
    ],
    metaTitle:
      "Best Digital Marketing Services Agency in Malaysia - NextBigBox",
    metaDescription:
      "Nextbigbox is the Best Digital Marketing Services Agency in Malaysia. Grow your Business with Multiple Services SEO, PPC, social media marketing etc.",
    metaKeywords: [
      "best digital marketing agency in Malaysia",
      "top digital marketing services",
      "SEO services in Malaysia",
      "PPC marketing Malaysia",
      "social media marketing Malaysia",
      "online marketing experts Malaysia",
      "digital marketing experts Malaysia",
    ],
    canonicalTag:
      "https://www.nextbigbox.io/digital-marketing-services-in-malaysia",
    geoTargetCode: [
      {
        name: "DC.title",
        content:
          "Best Digital Marketing Services Agency in Malaysia - NextBigBox",
      },
      {
        name: "geo.region",
        content: "MY",
      },
      {
        name: "geo.placename",
        content: "Malaysia",
      },
      {
        name: "geo.position",
        content: "4.569375;102.265682",
      },
      {
        name: "ICBM",
        content: "4.569375, 102.265682",
      },
    ],
    scriptTag: {
      "@context": "https://schema.org/",
      "@type": "WebSite",
      name: "Nextbigbox",
      url: "https://www.nextbigbox.io/",
      potentialAction: {
        "@type": "SearchAction",
        target:
          "https://www.nextbigbox.io/digital-marketing-services-in-malaysia?q={search_term_string}",
        "query-input": "required name=search_term_string",
      },
    },
    topDigitalMarketingImage: img3,
    bestDigitalMarketingImage: img4,
  },
];

export default singaporeList;
