import React, { useState } from "react";
import { Tabs } from "antd";
import "./BlogSectionComponentNew.css";
import dmb1 from "../../../media/digital_markegin_b1.png";
import seob1 from "../../../media/seo_blog1.png";
import techb1 from "../../../media/technical_blog1.png";
import techb2 from "../../../media/technical_blog2.png";
import hrmsb1 from "../../../media/hrmsb1.png";
import hrmsb2 from "../../../media/hrmsb2.png";
import hrmsb3 from "../../../media/hrmsb3.png";
import hrmsb4 from "../../../media/hrmsb4.png";
import gads1 from "../../../media/gadsb1.png";
import gads2 from "../../../media/gadsb2.png";
import gads3 from "../../../media/gadsb3.png";
import gads4 from "../../../media/gadsb4.png";
import Meta1 from "../../../media/metab1.png";
import Meta2 from "../../../media/metab2.png";
import Meta3 from "../../../media/metab3.png";
import Meta4 from "../../../media/metab4.png";
import webdb1 from "../../../media/webdb1.png";
import webdb2 from "../../../media/webdb2.jpg";
import webdb3 from "../../../media/webdb3.png";
import webdb4 from "../../../media/webdb4.png";
import seoB1 from "../../../media/seob1.png";
import seob2 from "../../../media/seob2.png";
import seob3 from "../../../media/seob3.png";
import seob4 from "../../../media/seob4.png";

const { TabPane } = Tabs;

// Custom component for Tab 1 header
const TabHeader = ({ name, activeKey, currentKey }) => (
  <div
    className={
      currentKey === activeKey
        ? "border border-[#ddd] py-[2px] rounded-2xl"
        : "custom-tab"
    }
  >
    <span className=" px-5 ">{name}</span>
    {/* Add any additional content you want */}
  </div>
);

const blogObj = [
  [
    {
      img: dmb1,
      title: "Digital Marketing",
      description:
        "Opened a new business but finding it hard to get the expected amount of customers? If yes, it might be due to lack of marketing or wrong type of marketing. Hiring a professional digital marketing agency can prove to be useful for your business to skyrocket your sales. Marketing agencies have experts that can assist you in understanding your target audience and help you manage you with their unmatched marketing skills. Continue reading this blog to know how to hire the perfect Digital Marketing Agency.",
      date: "07-04-2023",
      tags: ["Email Marketing"],
      link: "https://www.nextbigbox.io/blogs/",
    },
    {
      img: seob1,
      title: "SEO",
      description:
        "Today, for almost all individuals, whilst the need arises for facts, thoughts, techniques, or services, our initial step is to inquire with search engines like Google and Yahoo. Google, for example, gets an astounding 3.5 billion searches every day. Consequently, just as search engines have ingrained themselves into our day-by-day exercises, they have also emerged as a quintessential thing of several advertising techniques employed by means of agencies.",
      date: "27-02-2023",
      tags: ["Email Marketing"],
      link: "https://www.nextbigbox.io/blogs/",
    },
    {
      img: techb1,
      title: "Technical",
      description:
        "The secret’s no longer a secret, and there is no turning back: Marketers, commercial enterprise proprietors, and stakeholders are awakening to the monstrous fee of artificial intelligence (AI). In the domain of digital marketing, AI has formally emerged as the next principal development in online brand improvement advertising and marketing.AI-powered technology is actively hired to craft engaging content, glean valuable insights into target audiences, and surpass competitors inside the digital marketplace. By following health, your enterprise can set up deeper connections with its target demographic, optimize its advertising investments, and raise its brand identification.",
      date: "22-02-2023",
      tags: ["Email Marketing"],
      link: "https://www.nextbigbox.io/blogs/",
    },
    {
      img: techb2,
      title: "Technical",
      description:
        "WordPress stands tall as the dominant content material management device in a digital panorama teeming with possibilities and pitfalls, powering about 40% of all websites. However, this popularity doesn’t come without a charge – it makes WordPress sites top goals for cyber miscreants. Safeguarding your WordPress website is important to guard your facts, popularity, and agreement with your target audience. In this weblog, we will explore ten specific and innovative recommendations to reinforce the safety of your WordPress oasis.",
      date: "21-02-2023",
      tags: ["Email Marketing"],
      link: "https://www.nextbigbox.io/blogs/",
    },
  ],
  [
    {
      img: hrmsb1,
      title: "HRMS",
      description: `In today's dynamic work environments, fostering strong social interactions within companies is more than just a perk—it's a strategic imperative. Human Resource Management Systems (HRMS) play a pivotal role in nurturing these interactions, creating a cohesive and collaborative workplace culture. Let's delve into the significance of social interaction within companies and how HRMS can facilitate and enhance these connections.`,
      date: "20-02-2023",
      tags: ["Email Marketing"],
    },
    {
      img: hrmsb2,
      title: "HRMS",
      description: `HRMS payroll streamlines salary processing, tax deductions, and compliance with labor regulations. It automates payroll calculations, direct deposits, and generation of pay stubs, ensuring accuracy and efficiency. HRMS payroll modules integrate seamlessly with employee databases, time tracking systems, and accounting software, simplifying administrative tasks for HR professionals. By centralizing payroll management, HRMS enhances data security, reduces errors, and provides comprehensive reporting capabilities for better financial planning and compliance auditing.`,
      date: "25-02-2023",
      tags: ["Payroll"],
    },
    {
      img: hrmsb3,
      title: "HRMS",
      description: `In today's educational landscape, fostering collegial interactions among faculty, staff, and students is paramount for academic success and institutional growth. Leveraging technology, particularly through dedicated platforms like Learning Management Systems (LMS) and collaboration tools, educational institutions can facilitate seamless communication, collaboration, and knowledge sharing. From virtual classrooms and discussion forums to project collaboration spaces, these tools empower stakeholders to engage in meaningful interactions, exchange ideas, and cultivate a vibrant academic community.`,
      date: "28-02-2023",
      tags: ["Interaction"],
    },
    {
      img: hrmsb4,
      title: "HRMS",
      description: `Resource management optimizes the allocation and utilization of assets, including personnel, funds, and materials, to meet organizational objectives efficiently. It involves strategic planning, scheduling, and tracking resources throughout their lifecycle. By ensuring the right resources are available at the right time and place, resource management enhances productivity, minimizes costs, and maximizes the utilization of available resources.`,
      date: "22-02-2023",
      tags: ["Resource"],
    },
  ],
  [
    {
      img: gads1,
      title: "Google Ads",
      description: `Google Ads Optimization Strategies: Explore advanced techniques for optimizing Google Ads campaigns, including keyword selection, ad copywriting, bid management, and targeting options to improve performance and ROI`,
      date: "23-02-2023",
      tags: ["Google Ads Optimization"],
      link: "https://www.nextbigbox.io/blogs/",
    },
    {
      img: gads2,
      title: "Google Ads",
      description: `Content Marketing for Google Ads Success: Discuss how to integrate content marketing strategies with Google Ads campaigns, focusing on creating compelling content that aligns with user intent, enhances ad relevance, and improves quality scores for better ad placements and conversions.`,
      date: "21-02-2023",
      tags: ["Content Marketing"],
      link: "https://www.nextbigbox.io/blogs/",
    },
    {
      img: gads3,
      title: "Google Ads ",
      description: `Google Ads Analytics and Reporting: Dive into the analysis of Google Ads data using tools like Google Analytics and Google Ads reporting features. Learn how to interpret key metrics, track campaign performance, and derive actionable insights to refine ad strategies and improve results.`,
      date: "12-03-2023",
      tags: ["Google Ads Analytics"],
    },
    {
      img: gads4,
      title: "Google Ads ",
      description: `Effective Landing Page Design for Google Ads: Explore best practices for designing landing pages that complement Google Ads campaigns, focusing on factors such as page layout, messaging consistency, call-to-action optimization, and conversion rate optimization techniques to maximize the effectiveness of ad clicks.`,
      date: "23-03-2023",
      tags: ["Effective Landing"],
    },
  ],
  [
    {
      img: Meta1,
      title: "Meta",
      description: `Meta Platforms: Delve into Meta's vision for the metaverse, exploring its potential to revolutionize social interaction, virtual collaboration, and digital commerce, and its implications for society's future.`,
      date: "11-08-2023",
      tags: ["Meta"],
    },
    {
      img: Meta2,
      title: "Meta",
      description: `Meta's Content Moderation Challenges: Examine the complexities of content moderation on Meta's platforms, addressing issues such as misinformation, hate speech, and algorithmic biases, and the societal impacts of balancing free speech with user safety.`,
      date: "11-09-2023",
      tags: ["Meta"],
    },
    {
      img: Meta3,
      title: "Meta",
      description: `Meta's Influence on Digital Identity: Explore how Meta's platforms shape users' digital identities, privacy concerns surrounding data collection, and the societal implications of algorithmic personalization in shaping online behaviors and perceptions.`,
      date: "08-04-2023",
      tags: ["Meta"],
    },
    {
      img: Meta4,
      title: "Meta",
      description: `Meta's Role in Democratizing Access to Information: Investigate Meta's initiatives to expand internet access worldwide, empower marginalized communities, and bridge the digital divide, and the potential social impact of increased connectivity and information accessibility.`,
      date: "27-02-2023",
      tags: ["Meta"],
    },
  ],
  [
    {
      img: webdb1,
      title: "Web Development",
      description: `Web development involves designing, building, and maintaining websites and web applications. It encompasses front-end development for user interface design and client-side scripting, back-end development for server-side logic and database management, and full-stack development for comprehensive solutions. Web developers use programming languages like HTML, CSS, JavaScript, and frameworks like React and Node.js for efficient development.`,
      date: "17-09-2023",
      tags: ["Web Development"],
      link: "https://www.nextbigbox.io/blogs/",
    },
    {
      img: webdb2,
      title: "Web Development2",
      description: `Intranets serve as internal communication hubs, facilitating seamless collaboration, document sharing, and knowledge management within organizations. They streamline workflows, enhance team productivity, and foster a cohesive corporate culture. With customizable features like employee directories, news feeds, and project management tools, intranets empower employees to stay informed and engaged.`,
      date: "14-04-2023",
      tags: ["Web Development"],
    },
    {
      img: webdb3,
      title: "Web Development",
      description: `Web development involves designing, building, and maintaining websites and web applications. It encompasses frontend development for user interfaces, backend development for server-side logic, and database management. Technologies like HTML, CSS, JavaScript, and frameworks such as React and Node.js are used to create responsive, dynamic, and interactive web experiences for diverse digital platforms.`,
      date: "12-07-2023",
      tags: ["EWeb Development"],
    },
    {
      img: webdb4,
      title: "Web Development",
      description: `Cybersecurity safeguards digital assets from unauthorized access, data breaches, and cyber threats. It encompasses measures such as encryption, firewalls, and multi-factor authentication to protect networks, systems, and sensitive information. By implementing robust cybersecurity protocols, organizations mitigate risks, safeguard privacy, and uphold trust among stakeholders in an increasingly interconnected digital landscape.`,
      date: "12-09-2023",
      tags: ["Web Development"],
    },
  ],
  [
    {
      img: seoB1,
      title: "SEO",
      description: `SEO strategies involve keyword research, on-page optimization, and link building to improve website visibility in search engine results. Content quality, mobile-friendliness, and site speed are crucial factors. Utilizing meta tags, schema markup, and local SEO tactics can enhance online presence and attract targeted traffic.`,
      date: "27-02-2023",
      tags: ["Email Marketing"],
    },
    {
      img: seob2,
      title: "SEO",
      description: `Effective SEO drives organic traffic, boosts website credibility, and increases conversions. It improves search engine rankings, leading to higher visibility and brand awareness. By aligning content with user intent and providing valuable information, SEO enhances user experience and fosters long-term engagement and loyalty.`,
      date: "21-10-2023",
      tags: ["Email Marketing"],
    },
    {
      img: seob3,
      title: "SEO",
      description: ` Additionally, creating high-quality, engaging content tailored to user intent and search queries is vital. Utilizing multimedia formats, optimizing for voice search, and incorporating long-tail keywords can improve content relevance and attract niche audiences. Regularly updating and expanding content also signals freshness to search engines, improving rankings.`,
      date: "27-02-2023",
      tags: ["Email Marketing"],
    },
    {
      img: seob4,
      title: "SEO4",
      description: `Furthermore, effective SEO strategies yield sustainable, long-term benefits, driving consistent organic traffic and reducing reliance on paid advertising. Enhanced website visibility and authority foster trust among users, leading to increased brand recognition and competitive advantage in crowded digital marketplaces.`,
      date: "27-02-2023",
      tags: ["Email Marketing"],
    },
  ],
];

const BlogCategory = ({ data }) => {
  // truncate text
  function truncateText(text) {
    // Split the text into words
    let words = text.split(" ");

    // Check if the number of words exceeds 50
    if (words.length > 15) {
      // Join the first 50 words and append '...'
      return words.slice(0, 15).join(" ") + "...";
    } else {
      // Return the original text if it's less than or equal to 50 words
      return text;
    }
  }
  return (
    <div className=" scroll-container flex gap-6 justify-center px-2 p  pl-[480px] w-[100%] lg-pl-[480px] lg:pl-0 lg:min-w-[1000px] font-montserrat">
      {data.map((item, index) => {
        return (
          <div
            className="border bottom-1 border-[#ddd] p-2 rounded-md  min-w-[220px]"
            key={index}
          >
            <a href={item.link} target="blank">
              <img
                src={item.img}
                className="w-[16rem] h-[120px] max-h-[120px] "
                alt=""
              />
            </a>
            <div className="p-2">
              <p className="px-2 bg-[#ddd] font-semibold rounded-3xl text-[.7rem] text-[#111111] w-fit">
                {item.title}
              </p>
              <p className="mt-2 text-md font-semibold font-oswald min-h-[100px]">
                {truncateText(item.description)}
              </p>
              <p className="mt-2 text-[#828282] text-[.7rem] font-semibold">
                {item.date}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

function BlogSectionComponentNew() {
  const [currentKey, setCurrentKey] = useState(1);
  function callback(key) {
    setCurrentKey(key);
  }

  const [activeKey, setActiveKey] = useState("1");

  return (
    <div className="overflow-hidden my-12 lg:mt-12 mx-6 lg:mx-32 ">
      <h1 className="text-center text-2xl font-montserrat font-semibold mt-4 mb-3">
        Latest Blog
      </h1>
      <Tabs defaultActiveKey="1" onChange={callback} tabBarGutter={70}>
        <TabPane
          tab={
            <TabHeader
              name={"All Categories"}
              activeKey={"1"}
              currentKey={currentKey}
            />
          }
          key="1"
          className=" overflow-scroll w-100 lg:overflow-hidden"
        >
          <BlogCategory data={blogObj[0]} />
        </TabPane>
        <TabPane
          tab={
            <TabHeader name={"HRMS"} activeKey={"2"} currentKey={currentKey} />
          }
          key="2"
        >
          <BlogCategory data={blogObj[1]} />
        </TabPane>
        <TabPane
          tab={
            <TabHeader
              name={"Google Ads"}
              activeKey={"3"}
              currentKey={currentKey}
            />
          }
          key="3"
        >
          <BlogCategory data={blogObj[2]} />
        </TabPane>
        <TabPane
          tab={
            <TabHeader name={"Meta"} activeKey={"4"} currentKey={currentKey} />
          }
          key="4"
        >
          <BlogCategory data={blogObj[3]} />
        </TabPane>
        <TabPane
          tab={
            <TabHeader
              name={"Web Development"}
              activeKey={"5"}
              currentKey={currentKey}
            />
          }
          key="5"
        >
          <BlogCategory data={blogObj[4]} />
        </TabPane>
        <TabPane
          tab={
            <TabHeader name={"SEO"} activeKey={"6"} currentKey={currentKey} />
          }
          key="6"
        >
          <BlogCategory data={blogObj[5]} />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default BlogSectionComponentNew;
