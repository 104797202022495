import React from "react";
import "./HomeBanner.css";
import WebBanner from "../../media/web-banner.jpg";
function HomeBanner() {
  return (
    <div className="hero_banner">
      <div className="w-fit p-3 mt-[6rem] ml-[2rem] lg:p-0 lg:ml-[6rem] lg:mt-[10rem]">
        <h1 className="text-6xl font-montserrat text-white">
          Next Level Growth
        </h1>
        <p className="text-white font-montserrat">
          Unleash Your Potential with Cutting-Edge Marketing and IT Solutions.
        </p>
      </div>
    </div>
  );
}

export default HomeBanner;
