import React from 'react'
import './HrmsPageContent.css'
import HRMS_BANNER from "../../media/nbb_hrms_banner.webp";
import what_is_hrms_software from '../../media/what_is_hrms_software.webp'
import employee_management from '../../media/employee_management.webp'
import check_in_out from '../../media/hrms_check_in_out.webp'
import CtaComponent from '../CommonComponents/CtaComponent/CtaComponent'
import emp_work_hist from '../../media/employee_work_history.webp'
import attendance_detail from '../../media/attendance_details.webp'
import holiday_list from '../../media/holiday_list.png'
import SectionCard from '../CommonComponents/SectionCard'
import ContactComponent from '../CommonComponents/ContactComponent/ContactComponent'

function HrmsPageContent() {
  const DemoCtaText =
    "Together, we make your digital dreams a reality. Dive into the world of unmatched IT and digital marketing   solutions with us!";

  // window width
  let scrollValue = 0;
  const windowWidth = window.innerWidth;
  if (windowWidth > 500) {
    scrollValue = 3900;
  } else {
    scrollValue = 6150;
  }
  return (
    <div className="pt-[2rem] md:pt-0">
      <div className="main_hrms_page_content">
        <section className="hrms_hero">
          <h1>Human Resource Management Service - HRMS Software</h1>
          <p>Thrive Towards Success With HRMS Tools</p>
          <img className="mt-4" src={HRMS_BANNER} alt="img" />
        </section>
        <section className="hrms_section2">
          <div className="left" data-aos="zoom-in-right">
            <img src={what_is_hrms_software} className="md:pt-12" alt="img" />
          </div>
          <div className="right" data-aos="zoom-in-left">
            <h1>What is HRMS Software?</h1>
            <p className="mt-2">
              HRMS Software is a digitalized version of a{" "}
              <b>Human Resource management system</b> that is used to perform
              multiple functions of HR management and some of them are following
              like managing employee data, streamlining recruitment &
              onboarding, automating payroll & benefits, conducting performance
              management, tracking time & attendance, managing training &
              development, identify & develop talent, generate reports &
              analytics. It helps to automate all these functions.
            </p>
            <p>
              Its <b>cloud-based HRMS</b> software helps to manage all the
              functions in simple and easy ways so that anyone handling this can
              feel free to use this to accomplish all these functions.
            </p>
            <p className="mt-4">
              It becomes easier and more accessible with the HRMS web app which
              can be beneficial for the employees and employers as well while
              tracking all the credentials like leave, attendance, performance,
              and all.
            </p>
            <p>
              Overall, it helps to manage all the details, especially for the
              payroll and all that can track the details and complete
              <b> information transparently</b> without any misinformation.
            </p>
          </div>
        </section>
        <section className="hrms_section_3">
          <div className="left" data-aos="fade-up-right">
            <h1> Employee Management </h1>
            <p className="mt-2">
              A robust, transparent, and digital employee management system is
              always beneficial for employee management, and it always helps the
              HR team to track all the records of employees like
              <b>
                {" "}
                leave requests, approval, leave balance, salary status,
                attendance{" "}
              </b>
              and all the required things that are essential for the job of HR.
            </p>
            <p className="mt-2">
              It helps to manage and track the complete information for the
              growth of the organization because the employees and their
              performance records are essential simultaneously. Overall,
              choosing a <b> customized HRMS software</b> enhances transparency
              and work records precisely.
            </p>

            <ul>
              <li>
                <span>Easily manage and store all the data.</span>
              </li>
              <li>
                <span>Upload the documents at a similar place.</span>
              </li>
              <li>
                <span>Easy to handle all the functions. </span>
              </li>
              <li>
                <span>Personal dashboard for individuals.</span>
              </li>
            </ul>
          </div>
          <div className="right" data-aos="fade-up-left">
            <img src={employee_management} className="md:pt-20" alt="img" />
          </div>
        </section>
        <section className="hrms_section_4">
          <div className="left" data-aos="fade-right">
            <img src={check_in_out} className="md:p-8" alt="img" />
          </div>
          <div className="right" data-aos="fade-left">
            <h1>Punch In - Punch Out</h1>
            <p className="mt-3">
              Accomplishing work always is directly associated with time and
              it’s an essential task to track the time consumed by the employee
              on a per-day basis including lunch, tea breaks and all. HRMS
              software is the best component to track these which starts from
              the punch-in and ends at the punch-out with the daily works
              reports. It helps to keep an eye on the ongoing work and its
              consequences.
            </p>
            <p className="mt-3">
              Therefore, tracking all these with explicit information and
              reports helps the organization be aware of the performance of the
              employees and their<b> time management, punctuality,</b> and all.
            </p>

            <ul>
              <li>
                <span>Punch in with a single click.</span>
              </li>
              <li>
                <span>Stopwatch in the background.</span>
              </li>
              <li>
                <span>Calculate the break time and all.</span>
              </li>
            </ul>
          </div>
        </section>
      </div>
      <CtaComponent
        description={DemoCtaText}
        scrollVal={scrollValue}
        scrollDuration={100}
      />
      <div className="main_hrms_page_content main_hrms_page_content_next">
        <section className="hrms_section_5">
          <div className="left" data-aos="zoom-in">
            <img src={emp_work_hist} className="md:pt-8" alt="img" />
          </div>
          <div className="right" data-aos="zoom-out">
            <h1>Employee Work History</h1>
            <p>
              The performance and work history of employees can be captured
              easily in the HRMS software which helps to save the data and
              complete information of the employees. Therefore, it is also
              essential for all the managers and senior managers to accumulate
              all the <b>data and feedback</b> that can help be updated forever
              especially while evaluating the credibility of the employees.
            </p>
            <p>
              Including punctuality, attendance, requirements of leave and all,
              HRMS always keep an eye on the <b> performance</b> of employees
              and finally, it can automatically evaluate the performance score
              of employees for multiple reasons like increments, promotions, and
              all. And HRMS helps you with everything.
            </p>
            <p>
              A detailed note of error-free everyday work report by using HRMS
              application. <b> Daily reports </b>are useful to HR Managers to
              consider performance management for knowing the work status by
              using a good free hrm software.
            </p>
            <ul>
              <li>
                <span>Track In-Out timing. </span>
              </li>
              <li>
                <span>Keep the records on per daily basis.</span>
              </li>
              <li>
                <span>Accumulate the work performance of employees.</span>
              </li>
            </ul>
          </div>
        </section>

        <section className="hrms_section_3">
          <div className="left" data-aos="fade-up">
            <h1> Attendance Trail </h1>
            <p className="mt-3">
              Attendance is the prominent factor that is always considered by
              employers or institutions for the betterment of the organizations,
              and in the digital era, it has become easy and <b> hassle-free</b>{" "}
              with the help of HRMS. The complete information and timing can be
              captured transparently.
            </p>
            <p className="mt-3">
              Let’s associate your company with precise and customized HRMS that
              can minimize the difficulties of <b>tracking attendance</b> and
              managing all the reports and information of the employees and it
              helps you evaluate the performance and punctual behaviour of your
              employees. Since it’s not just software, it’s the component for
              the growth of your organization with the explicit information of
              employees and all.
            </p>

            <ul>
              <li>
                <span>
                  A list of all employee's Punch IN / Punch OUT entries.
                </span>
              </li>
              <li>
                <span>One click to view status report.</span>
              </li>
              <li>
                <span>Generate request to update work hours.</span>
              </li>
              <li>
                <span>Send approve/reject event notifications.</span>
              </li>
            </ul>
          </div>
          <div className="right" data-aos="fade-down">
            <img src={attendance_detail} className="md:pt-16" alt="img" />
          </div>
        </section>

        <section className="hrms_section_5">
          <div className="left" data-aos="zoom-in-right">
            <img src={holiday_list} alt="img" />
          </div>
          <div className="right md:pt-4" data-aos="zoom-in-left">
            <h1>Holiday List</h1>
            <p>
              As per the psychology of corporate life, everyone has the desire
              to get the brief details of the holiday list and almost all the
              employees invariably want to be updated for this. Since they
              deserve to have explicit details of holidays, they must have a
              calendar to schedule the planning as per their leave and
              convenience.
            </p>
            <p>
              HRMS helps you update the calendar and list of holidays which can
              be used for the updating of the overall list and individual also
              for the personal dashboard.
            </p>
            <ul>
              <li>
                <span>Plan a vacation in advance.</span>
              </li>
              <li>
                <span>Apply early for smooth approval process.</span>
              </li>
              <li>
                <span>Set due date and maintain your work delivery.</span>
              </li>
              <li>
                <span>Design your monthly calendar accordingly.</span>
              </li>
            </ul>
          </div>
        </section>
        {/* <section>
          <SmallCTA />
        </section> */}
      </div>
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
      >
        <ContactComponent />
      </SectionCard>
    </div>
  );
}

export default HrmsPageContent