import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Components/CommonComponents/Navbar";
import SectionCard from "../Components/CommonComponents/SectionCard";
import WebSeoContent from "../Components/WebSeoContent/WebSeoContent";
import NewFooterComponent from "../Components/CommonComponents/NewFooterComponent/NewFooterComponent";
import Seo from "../Components/CommonComponents/Seo";

function WebSeo() {
  const [test, setTest] = useState(false);
  const isNavbarOpened = () => {
    setTest((prev) => !test);
  };

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <Seo
        name={"Nextbigbox"}
        type={"Article"}
        title={"Best SEO Company | Search Engine Optimization NextBigBox"}
        description={
          "Search engine optimization firm Nextbigbox offers complete search engine marketing solutions. We are an SEO company that has won awards."
        }
        canonical_url="https://www.nextbigbox.io/search-engine-optimization"
        metaKeywords={[
          "Best SEO Company",
          "Search Engine Optimization",
          "SEO Services",
          "NextBigBox",
          "SEO Experts",
          "SEO Solutions",
          "Organic Traffic",
          "Online Presence",
          "Digital Marketing",
        ]}
      />
      {/* Navbar Start  */}
      <Navbar isNavbarOpened={isNavbarOpened} />
      {/* Navbar End  */}
      {/* Web Seo Main Component  */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <WebSeoContent />
      </SectionCard>
      {/* Web Seo  Main Component End */}
      {/* FooterSection Start */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <NewFooterComponent />
      </SectionCard>
      {/* FooterSection End */}
    </div>
  );
}

export default WebSeo;
