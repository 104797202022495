import React from 'react'
import './Progress.css'
import GIF from '../../media/gif3.gif'

function Progress() {
  return (
    <div className="coming_soon_progress_main">
      <div className="main_text">
        <p className="head">we are building something big!</p>
        <p className="sub_head">Stay tuned for latest Updates</p>
      </div>
      <div className="gif">
        <img src={GIF} alt="GIF" draggable="false" />
      </div>
    </div>
  );
}

export default Progress