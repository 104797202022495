import React from "react";
import { Helmet } from "react-helmet-async";
export default function Seo({
  title = "",
  description = "",
  type,
  canonical_url = "",
  metaKeywords = [],
  geoTargetCode = [],
  scriptTag = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: "nextbigbox",
    url: "https://www.nextbigbox.io/",
    potentialAction: {
      "@type": "SearchAction",
      target: "https://www.nextbigbox.io/?q={search_term_string}",
      "query-input": "required name=search_term_string",
    },
  },
}) {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      {/* <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} /> */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* End Twitter tags */}
      {/* Canonical url */}
      <link rel="canonical" href={canonical_url} />
      {/* Meta Keywords */}
      <meta name="keywords" content={[...metaKeywords]} />

      {/*  */}

      {/* Geo target */}
      {geoTargetCode.map((data) => {
        <meta name={data.name} content={data.content} />;
      })}

      {/* script */}
      <script type="application/ld+json">{JSON.stringify(scriptTag)}</script>
    </Helmet>
  );
}
