import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../Components/CommonComponents/Navbar";
import SectionCard from "../Components/CommonComponents/SectionCard";
import ClientComponent from "../Components/CommonComponents/ClientComponent/ClientComponent";
import HomeBanner from "../Components/CommonComponents/HomeBanner";
import SlickCarousel from "../Components/SlickCarousal/SlickCarousel";
import NewFooterComponent from "../Components/CommonComponents/NewFooterComponent/NewFooterComponent";
import CtaSection from "../Components/CommonComponents/CtaSection/CtaSection";
import CtaSection2 from "../Components/CommonComponents/CtaSection2/CtaSection2";
import CaseStudies from "../Components/CommonComponents/CaseStudies/CaseStudies";
import ReviewComponent from "../Components/CommonComponents/ReviewComponent/ReviewComponent";
import BlogSectionComponentNew from "../Components/CommonComponents/BlogSectionComponentNew/BlogSectionComponentNew";
import RecognitionComponent from "../Components/CommonComponents/RecognitionComponent/RecognitionComponent";
import Seo from "../Components/CommonComponents/Seo";
import SliderComponent from "../Components/CommonComponents/SliderComponent";

function Home() {
  const [test, setTest] = useState(false);
  const isNavbarOpened = () => {
    setTest((prev) => !test);
  };

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // Demo Cta Test

  const DemoCtaText =
    "We transform your dream of streamlining your business online into reality. Let’s dive into the world of IT and digital marketing solutions with our expertise.";

  return (
    <div>
      <Seo
        name={"Nextbigbox"}
        type={"Article"}
        title={"Best Digital marketing Agency |  CRM Software | NextBigBox"}
        description={
          "Your Best Digital Marketing Agency and CRM Software Solution with NextBigBox to boost your business growth and innovative marketing strategies and top customer relationship."
        }
        canonical_url="https://www.nextbigbox.io/"
        metaKeywords={[
          "Best Digital Marketing Agency",
          "Digital Marketing",
          "Best SEO Services",
          "best Social Media Marketing",
          "best Social Media Marketing",
          "Content Marketing",
          "PPC Advertising",
          "Email Marketing",
          "Web Development",
          "Conversion Optimization",
          "Search Engine Marketing",
          "hrms CRM software",
        ]}
      />
      {/* Navbar Start  */}
      <Navbar isNavbarOpened={isNavbarOpened} isHomePage={true} />
      {/* Navbar End  */}

      {/* HeroSection Start  */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-auto"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <HomeBanner />
      </SectionCard>
      {/* HeroSection End */}
      {/* Our Experties Start  */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-auto"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <SlickCarousel />
      </SectionCard>
      {/* Our Experties End  */}

      {/* Case Studies Start */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-auto"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <CaseStudies />
      </SectionCard>
      {/* Case Studies End */}

      {/* Recognition Component Start */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-auto"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20 "}
        visible={test ? "hidden" : "show"}
      >
        {/* <RecognitionComponent /> */}
        <SliderComponent />
      </SectionCard>

      {/* Recognition Component End  */}

      {/* Cta Section 1 Start */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-auto"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <CtaSection />
      </SectionCard>
      {/* Cta Section 1 End  */}

      {/* Review Section Start  */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-auto"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <ReviewComponent />
      </SectionCard>
      {/* Review Section End  */}

      {/* Our Clients Start */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-auto"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <ClientComponent />
      </SectionCard>
      {/* Our Clients End */}

      {/* Blog section Start */}
      {/* <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-auto"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <BlogSectionComponentNew />
      </SectionCard> */}
      {/* Blog section End */}

      {/* Cta Section 2 Start  */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-auto"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <CtaSection2 />
      </SectionCard>
      {/* Cta Section 2 End  */}
      {/* FooterSection Start */}
      <SectionCard
        position={"relative"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800"}
        visible={test ? "hidden" : "show"}
      >
        <NewFooterComponent />
      </SectionCard>
    </div>
  );
}

export default Home;
