import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./WebDevelopmentPageContent.css";
import Cards from "../CommonComponents/Cards/Cards";
import WebDevelopmentServiceImg from "../../media/wev_development_banner.webp";
import IndustriesWeServer from "../../media/Industry.webp";
import html_logo from "../../media/html-icon.jpg";
import react_logo from "../../media/react-icon.png";
import wp_logo from "../../media/wordpress-icon.png";
import angular_logo from "../../media/angular.png";
import vue_logo from "../../media/vue-icon.png";
import next_logo from "../../media/next-js-logo.png";
import redux_logo from "../../media/redux-icon.png";
import Bootstrap_logo from "../../media/bootstrap-logo.png";
import mui_logo from "../../media/mui_logo.png";
import git_logo from "../../media/github_logo.png";
import tailwind_logo from "../../media/tailwind_logo.png";
import chakraui_logo from "../../media/chakraui_logo.png";
import php_logo from "../../media/php_logo.png";
import nodejs_logo from "../../media/nodejs_logo.png";
import expressjs_logo from "../../media/expressjs_logo.png";
import mongodb_logo from "../../media/mongodb_logo.png";
import mysql_logo from "../../media/mysql_logo.png";
import laravel_logo from "../../media/laravel_logo.png";
import codeigniter_logo from "../../media/codeigniter_logo.png";
import cakephp_logo from "../../media/cackphp_logo.png";
import yilphp_logo from "../../media/yilphp_logo.png";
import aws_logo from "../../media/aws_logo.png";
import googlecloud_logo from "../../media/googlecloud_logo.png";
import shocketio_logo from "../../media/shocketio_logo.png";
import sdlc1 from "../../media/sdlc1.webp";
import sdlc2 from "../../media/sdlc2.webp";
import sdlc3 from "../../media/sdlc3.webp";
import sdlc4 from "../../media/sdlc4.webp";
import sdlc5 from "../../media/sdlc5.webp";
import sdlc6 from "../../media/sdlc6.webp";
import sdlc7 from "../../media/sdlc7.webp";
import sdlc8 from "../../media/sdlc8.webp";
import FaqSectionComponent from "../CommonComponents/FaqSectionComponent/FaqSectionComponent";
import ContactComponent from "../CommonComponents/ContactComponent/ContactComponent";
import SectionCard from "../CommonComponents/SectionCard";
gsap.registerPlugin(ScrollTrigger);

function WebDevelopmentPageContent({ test }) {
  // scroll animation
  useEffect(() => {
    gsap.from(".storyHeadingUp", {
      scrollTrigger: {
        trigger: ".storyHeadingUp",
        scrub: false,
        start: "top 90%",
        end: "bottom 60%",
        toggleActions: "play reverse play reverse",
      },
      y: 100,
      opacity: 0,
    });
    gsap.to(".storyHeadingUp", {
      scrollTrigger: {
        trigger: ".storyHeadingUp",
        scrub: false,
        start: "top 90%",
        end: "bottom 60%",
        toggleActions: "play reverse play reverse",
      },
      y: 0,
      opacity: 1,
      duration: 1,
    });
  }, []);

  const data = [
    {
      heading: "Research",
      icon: sdlc1,
      desc: "Interact with clients to get insight into their requirements.",
    },
    {
      heading: "Define",
      icon: sdlc2,
      desc: "With the evaluation and requirements, we set the target. ",
    },
    {
      heading: "Establish Content Priority",
      icon: sdlc3,
      desc: "With the content priority we design the required layout. ",
    },
    {
      heading: "Establish Style",
      icon: sdlc4,
      desc: "Create the design as per the requirements and expectations.",
    },
    {
      heading: "Finalized Design",
      icon: sdlc5,
      desc: "With the code responsive templates, we start with mobile responsive. ",
    },
    {
      heading: "Code Responsive Template",
      icon: sdlc6,
      desc: "With precise synthesis methods, we integrate the template with content management.",
    },
    {
      heading: "CMS integration",
      icon: sdlc7,
      desc: "With the requirements of marketing, we apply the CMS integration for certain updates and changes.  ",
    },
    {
      heading: "Deployment",
      icon: sdlc8,
      desc: "Our development services are precisely done and can undergo deployment.",
    },
  ];
  const faqData = [
    {
      q: "What is difference between the front and back-end web development?",
      a: "Front-end development stands for the digital appearance of the static website where your customers can get ideas and information, whereas back-end development is implemented to impart dynamic services like placing orders, saving databases and all.",
    },
    {
      q: "How much time will it take to initiate the online business via a website?",
      a: "It will depend on your choice like several pages, features, services and all and as per their length, development parts and all it will depend. However, a lending page can be created shortly.",
    },
    {
      q: " Will it take longer to proceed with the full stake development?",
      a: "We always care about the delivery on the committed time, however, the actual time required for the full stake development will take some time.",
    },
    {
      q: "What is the budget for the web development services? ",
      a: "It will depend on your requirements and the nature of your business. ",
    },
    {
      q: "How can it help me to grow and generate business? ",
      a: "It will impart digital presence to your business and once you can connect with the potential customers, your business growth will be elevated.",
    },
  ];
  const faqHeading =
    "Let’s resolve all the queries that might be emerging in your mind, especially for the web development services. And we are here to answer until your last question is resolved.  ";

  return (
    <div className="main_web_development_page">
      <section className="hero-section">
        <div className="left" data-aos="zoom-in-right">
          <h1>Web Development Services</h1>
          <p>
            Welcome to the nextbigbox web development service which is our
            strength and speciality in front-end and back-end as well. Where our
            expert’s innovative ideas and expertise ensure the accomplishment of
            your dreams of establishing an online business and becoming a brand
            in a limited time. Using prominent techniques for front-end
            development like HTML, React, WordPress, Bootstrap, etc, our experts
            always design an SEO-Friendly and user-experience-based website
            considering the holistic requirements of digital marketing.
          </p>
          <p>
            Similarly, our back-end development team is keen to deliver quality
            design and back-end service to minimize your stress in data handling
            in all possible ways. Using the trending components of back-end
            developments like PHP, Node, Express, Laravel, Codeigniter, MySQL,
            AWS, etc., we can ensure that your customers will have the best
            experience with your responsive websites with the precise page
            loading speed.
          </p>
          <p>
            Overall, we are a one-stop solution for your requirements,
            especially for the website development from where your digital
            journey starts.{" "}
          </p>
          <ul className="my-feature">
            <ul>
              <li>User-Centric Design (UI/UX)</li>
              <li>Optimized Performance</li>
              <li>Responsive Design</li>
            </ul>
            <ul>
              <li>Cross-Browser Compatibility</li>
              <li>SEO-Friendly </li>
              <li>Data Privacy and Compliance</li>
            </ul>
          </ul>
        </div>
        <div className="right md:pt-28" data-aos="zoom-in-left">
          <h1>Custom Web Development Services</h1>
          <img
            src={WebDevelopmentServiceImg}
            className="web_development_banner"
            alt="img"
            draggable="false"
          />
        </div>
      </section>
      <section className="IndustriesWeServer">
        <h2>Industries We Serve</h2>
        <img
          src={IndustriesWeServer}
          alt="img"
          draggable="false"
          data-aos="zoom-out-up"
        />
      </section>
      <section className="tech_stack">
        <h2> Tech Stack We Use </h2>
        <div>
          <div className="left">
            <h2 className="font-oswald">Front-End</h2>
            <div>
              <div>
                <ul>
                  <li data-aos="zoom-in-up">
                    <img src={html_logo} alt="img" draggable="false" />
                  </li>
                  <li data-aos="zoom-in-up">
                    <img src={react_logo} alt="img" draggable="false" />
                  </li>
                  <li data-aos="zoom-in-up">
                    <img src={next_logo} alt="img" draggable="false" />
                  </li>
                  <li data-aos="zoom-in-up">
                    <img src={angular_logo} alt="img" draggable="false" />
                  </li>
                  <li data-aos="zoom-in-up">
                    <img src={vue_logo} alt="img" draggable="false" />
                  </li>
                  <li data-aos="zoom-in-up">
                    <img src={wp_logo} alt="img" draggable="false" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="pt-2">
              <div>
                <ul>
                  <li data-aos="zoom-in-up">
                    <img src={Bootstrap_logo} alt="img" draggable="false" />
                  </li>
                  <li data-aos="zoom-in-up">
                    <img src={redux_logo} alt="img" draggable="false" />
                  </li>
                  <li data-aos="zoom-in-up">
                    <img src={mui_logo} alt="img" draggable="false" />
                  </li>
                  <li data-aos="zoom-in-up">
                    <img src={git_logo} alt="img" draggable="false" />
                  </li>
                  <li data-aos="zoom-in-up">
                    <img src={tailwind_logo} alt="img" draggable="false" />
                  </li>
                  <li data-aos="zoom-in-up">
                    <img src={chakraui_logo} alt="img" draggable="false" />
                  </li>
                </ul>
              </div>
            </div>
            <div></div>
          </div>
          <div className="right pt-2">
            <h2 className="pb-6 font-oswald">Back-End</h2>
            <div>
              <div>
                <ul>
                  <li data-aos="zoom-out-up">
                    <img src={php_logo} alt="img" draggable="false" />
                  </li>
                  <li data-aos="zoom-out-up">
                    <img src={nodejs_logo} alt="img" draggable="false" />
                  </li>
                  <li data-aos="zoom-out-up">
                    <img src={expressjs_logo} alt="img" draggable="false" />
                  </li>
                  <li data-aos="zoom-out-up">
                    <img src={laravel_logo} alt="img" draggable="false" />
                  </li>
                  <li data-aos="zoom-out-up">
                    <img src={codeigniter_logo} alt="img" draggable="false" />
                  </li>
                  <li data-aos="zoom-out-up">
                    <img src={mongodb_logo} alt="img" draggable="false" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="">
              <div>
                <ul>
                  <li data-aos="zoom-out-up">
                    <img src={cakephp_logo} alt="img" draggable="false" />
                  </li>
                  <li data-aos="zoom-out-up">
                    <img src={yilphp_logo} alt="img" draggable="false" />
                  </li>
                  <li data-aos="zoom-out-up">
                    <img src={mysql_logo} alt="img" draggable="false" />
                  </li>
                  <li data-aos="zoom-out-up">
                    <img src={aws_logo} alt="img" draggable="false" />
                  </li>
                  <li data-aos="zoom-out-up">
                    <img src={googlecloud_logo} alt="img" draggable="false" />
                  </li>
                  <li data-aos="zoom-out-up">
                    <img src={shocketio_logo} alt="img" draggable="false" />
                  </li>
                </ul>
              </div>
            </div>
            <div></div>
            <div></div>
          </div>
        </div>
      </section>
      <section className="deployment_lc">
        <div className="web_dev_life_cycle ">
          <h1 className="text-2xl font-bold text-center pt-6 pb-6 m-6">
            Development Life Cycle
          </h1>
          <div className="deployment_lc_item">
            {data.map((item, index) => (
              <Cards
                heading={item.heading}
                icon={item.icon}
                desc={item.desc}
                key={index}
                className={"w-30"}
              />
            ))}
          </div>
        </div>
      </section>
      <section className="web_dev_faq_section mt-8">
        <FaqSectionComponent faqHeading={faqHeading} faqData={faqData} />
      </section>
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
      >
        <ContactComponent padding={true} />
      </SectionCard>
    </div>
  );
}

export default WebDevelopmentPageContent;
