import React from 'react'
import './Cards.css'

function Cards({heading, icon, desc,className}) {
  return (
    <div className="card border service_card">
      <img src={icon} alt="img" draggable="false" />
      <h2 className='font-oswald'>{heading}</h2>
      <p className={className}>{desc}</p>
    </div>
  );
}

export default Cards