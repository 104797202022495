import React, { useState, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./ClientComponent.css";

// Import all your images
import c1 from "../../../media/c1.jpg";
import c2 from "../../../media/c2.jpg";
import c3 from "../../../media/c3.jpg";
import c4 from "../../../media/c4.jpg";
import c5 from "../../../media/c5.jpg";
import c6 from "../../../media/c6.jpg";
import c7 from "../../../media/c7.jpg";
import c8 from "../../../media/c8.jpg";
import c9 from "../../../media/c9.jpg";
import c10 from "../../../media/c10.jpg";
import c11 from "../../../media/c11.jpg";
import c12 from "../../../media/c12.jpg";
import c13 from "../../../media/c13.jpg";
import c14 from "../../../media/c14.jpg";
import c15 from "../../../media/c15.jpg";
import c16 from "../../../media/c16.jpg";
import c17 from "../../../media/c17.jpg";
import c18 from "../../../media/c18.webp";
import c19 from "../../../media/c19.webp";
import c20 from "../../../media/c20.webp";
import c21 from "../../../media/c21.webp";
import c22 from "../../../media/c22.webp";
import c23 from "../../../media/c23.webp";
import s1 from "../../../media/1.svg"
import s2 from "../../../media/2.svg"
import s3 from "../../../media/3.svg"
import s4 from "../../../media/4.svg"
import s5 from "../../../media/5.svg"
import s6 from "../../../media/6.svg"
import s7 from "../../../media/7.svg"
import s8 from "../../../media/8.svg"
import s9 from "../../../media/9.svg"
import s10 from "../../../media/10.svg"
// import s11 from "../../../media/11.svg"



// import c24 from "../../../media/c24.webp";
// import c25 from "../../../media/c25.webp";

// import c26 from "../../../media/c26.webp";
// import c27 from "../../../media/c27.webp";
// import c28 from "../../../media/c28.webp";
// import c29 from "../../../media/c29.webp";

gsap.registerPlugin(ScrollTrigger);

function ClientComponent({Heading = "Our Clients"}) {
  // Store your image imports in an array
  const allImages = [
    s1,
    s2,
    s3,
    s4,
    s5,
    s6,
    s7,
    s8,
    s9,
    s10,
    // s11,
    c1,
    // c2,
    // c3,
    // // c4,
    // c5,
    // c6,
    // c7,
    // c19,
    // c8,
    // c9,
    // c10,
    // c11,
    // c12,
    // c13,
    // c14,
    // c15,
    // c16,
    // c17,
    // c18,
    // // c20,
    // c21,
    // c22,
    // c23,
    // // c26,
    // // c27,
    // // c28,
    // // c29,
  ];

  const [usedImages, setUsedImages] = useState(allImages.slice(0, 10));
  const [unUsedImages, setUnUsedImages] = useState(allImages.slice(10));
  const [animationClasses, setAnimationClasses] = useState(
    usedImages.map((_, index) => "")
  );

  useEffect(() => {
    const imageChangeInterval = setInterval(() => {
      const randomImageIndex = Math.floor(Math.random() * 10);
      // Create a copy of the animation classes array
      const updatedAnimationClasses = [];
      // Apply animation classes to the old and new images
      updatedAnimationClasses[randomImageIndex] = "swipe-up";
      updatedAnimationClasses[(randomImageIndex + 10) % 10] = "new-image";

      setAnimationClasses(updatedAnimationClasses);

      // Create a copy of usedImages array and update it
      const updatedUsedImages = [...usedImages];
      updatedUsedImages[randomImageIndex] = unUsedImages[0];
      setUsedImages(updatedUsedImages);

      // Create a copy of unUsedImages array and update it
      const updatedUnUsedImages = [...unUsedImages];
      updatedUnUsedImages.shift();
      updatedUnUsedImages.push(usedImages[randomImageIndex]);
      setUnUsedImages(updatedUnUsedImages);
    }, 2000);

    return () => {
      clearInterval(imageChangeInterval);
    };
  }, [usedImages, unUsedImages, animationClasses]);

  return (
    <div className="main_client_section mt-4 font-semibold font-montserrat bg-white">
      <h1 className=" text-lg lg:text-2xl mb-6 " data-aos="fade-down">
        {Heading}
      </h1>
      <div className="imageContainer">
        {usedImages.map((imageUrl, index) => (
          <div className={`imageItem ${animationClasses[index]}`} key={index}>
            <img
              className=""
              src={imageUrl}
              alt={` ${index + 1}`}
              width={"200px"}
              height={"50px"}
              draggable="false"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ClientComponent;
