import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./AboutPageContent.css";
import ABOUT_HERO from "../../media/about_us_hero.webp";
import { Link } from "react-router-dom";
import SectionCard from "../CommonComponents/SectionCard";
import CtaComponent from "../CommonComponents/CtaComponent/CtaComponent";
import BUILDING1 from "../../media/building.svg";
import BUILDING2 from "../../media/building 2.svg";
import BUILDING3 from "../../media/building 3.svg";
import BUILDING4 from "../../media/building 4.svg";
import ARROW from "../../media/arrow.svg";
import CORE_VALUES from "../../media/empowering_people.webp";
import ContactComponent from "../CommonComponents/ContactComponent/ContactComponent";
gsap.registerPlugin(ScrollTrigger);

function AboutPageContent({ test }) {
  // Demo Cta Test
  // scroll animation
  useEffect(() => {
    //From Left
    gsap.from(".OurValueLeft", {
      scrollTrigger: {
        trigger: ".OurValueLeft",
        scrub: false,
        // markers: true,
        start: "top 90%",
        end: "bottom 40%",
        toggleActions: "play reverse play reverse",
      },
      x: -100,
      opacity: 0,
    });
    gsap.to(".OurValueLeft", {
      scrollTrigger: {
        trigger: ".OurValueLeft",
        scrub: false,
        // markers: true,
        start: "top 90%",
        end: "bottom 40%",
        toggleActions: "play reverse play reverse",
      },
      x: 0,
      opacity: 1,
      duration: 1,
    });
    //From Left
    gsap.from(".OurValueRigt", {
      scrollTrigger: {
        trigger: ".OurValueRigt",
        scrub: false,
        // markers: true,
        start: "top 90%",
        end: "bottom 40%",
        toggleActions: "play reverse play reverse",
      },
      x: 100,
      opacity: 0,
    });
    gsap.to(".OurValueRigt", {
      scrollTrigger: {
        trigger: ".OurValueRigt",
        scrub: false,
        // markers: true,
        start: "top 90%",
        end: "bottom 40%",
        toggleActions: "play reverse play reverse",
      },
      x: 0,
      opacity: 1,
      duration: 1,
    });
  }, []);
  const DemoCtaText =
    "We transform your dream of streamlining your business online into reality. Let’s dive into the world of IT and digital marketing solutions with our expertise.";

  // window width
  let scrollValue = 0;
  const windowWidth = window.innerWidth;
  if (windowWidth > 500) {
    scrollValue = 1900;
  } else {
    scrollValue = 3550;
  }
  const ScrollDown = (scrollVal, scrollDuration) => {
    let windowWidth = window.innerWidth;
    if (windowWidth < 500) {
      let val = scrollVal - window.scrollY;
      setTimeout(() => {
        window.scrollBy({ top: val, letf: 0, behavior: "smooth" });
        // console.log('val',val);
      }, scrollDuration);
    } else {
      let val = scrollVal - window.scrollY;
      setTimeout(() => {
        window.scrollBy({ top: val, letf: 0, behavior: "smooth" });
        // console.log(val);
      }, scrollDuration);
    }
  };
  return (
    <div className="about_page_content">
      {/* Hero  */}
      <div className="about_page_content_hero ">
        <div className="about_page_content_right" data-aos="fade-right">
          <img src={ABOUT_HERO} alt="About_Us_Hero" draggable="false" />
        </div>
        <div className="about_page_content_left" data-aos="fade-left">
          <div className="about_page_content_left_subtitle">ABOUT US</div>
          <div className="about_page_content_left_heading font-oswald">
            <p>
              Enriching Business with Advanced IT and Digital Marketing
              Services.
            </p>
          </div>
          <div className="about_page_content_left_description">
            As an established IT and Digital Marketing Company, we have
            proficiency and expertise in streamlining and enriching the business
            on Digital platforms, where with our state-of-the-art techniques and
            profound experience in dealing with enormous clients, we help you
            ensure maximum revenue generation and desired growth in your
            business.
          </div>
          <div className="about_page_hero_btn">
            <li className="list-none">
              <button
                onClick={() => ScrollDown(scrollValue, 100)}
                className="border-1 bg-blue-500 rounded-md text-white border-black px-3 py-1"
              >
                Request a Demo
              </button>
            </li>
          </div>
        </div>
      </div>
      {/* Our Story  */}
      <div className="about_page_our_story">
        <h1 className="font-oswald" data-aos="fade-up">
          Our Story
        </h1>
        <div>
          <div className="our_story_item" data-aos="zoom-out-right">
            <img
              src={BUILDING1}
              alt="2021"
              className="w-36 h-36"
              draggable="false"
            />
            <h3 className="font-oswald">2021</h3>
            <p>
              In 2021, with the inception we had trial with our in house
              projects we got success in establishing them robustly.
            </p>
          </div>
          <img
            src={ARROW}
            alt="arrow"
            className="w-10 h-10"
            draggable="false"
          />
          <div className="our_story_item " data-aos="zoom-out-up">
            <img
              src={BUILDING2}
              alt="2022"
              className="w-36 h-36"
              draggable="false"
            />
            <h3 className="font-oswald">2022</h3>
            <p>
              With our prior experience and successful trial, we started wide
              spreading our service for our prominent clients in 2022.
            </p>
          </div>
          <img
            src={ARROW}
            alt="arrow"
            className="w-10 h-10"
            draggable="false"
          />
          <div className="our_story_item " data-aos="zoom-out-up">
            <img
              src={BUILDING3}
              alt="2023"
              className="w-36 h-36"
              draggable="false"
            />
            <h3 className="font-oswald">2023</h3>
            <p>
              Once we revealed our credibility, enormous clients associated with
              us and they ensured the revenue growth in 2023.
            </p>
          </div>
          <img
            src={ARROW}
            alt="arrow"
            className="w-10 h-10"
            draggable="false"
          />
          <div className="our_story_item " data-aos="zoom-out-left">
            <img
              src={BUILDING4}
              alt="2024"
              className="w-36 h-36"
              draggable="false"
            />
            <h3 className="font-oswald">2024</h3>
            <p>
              In 2024, with the prior experience we have been functioning with
              our esteemed clients and moving forward with accelerated speed.
            </p>
          </div>
        </div>
      </div>
      {/* Cta  */}
      <div>
        <SectionCard
          position={"relative"}
          height={"h-auto"}
          width={"w-auto"}
          backgroundColor={"bg-red"}
          color={"text-yellow"}
          className={"text-slate-800 mt-20 "}
          visible={test ? "hidden" : "show"}
        >
          <CtaComponent
            description={DemoCtaText}
            scrollVal={scrollValue}
            scrollDuration={100}
          />
        </SectionCard>
      </div>
      {/* Our Values  */}
      <div className="about_page_content_hero ">
        <div className="about_page_content_left " data-aos="zoom-out-right">
          <div className="about_page_content_left_subtitle">Our Motive</div>
          <div className="about_page_content_left_heading font-oswald">
            <p>Empowering People</p>
          </div>
          <div className="about_page_content_left_description">
            At NextBigBox, we have multiple integrated services that are
            associated with each other and using all these invariable components
            starting from website development with front-end and back-end, SEO,
            SMO, CRM, APIs HRMS, and all, we help you empower and enrich your
            business in all the possible ways and therefore, we are one-stop
            solution for your quest of the best IT and Digital Marketing
            services that can impart growth and outstanding revenue generation.
          </div>
        </div>
        <div className="about_page_content_right " data-aos="zoom-out-left">
          <img
            src={CORE_VALUES}
            alt="About_Us_Hero"
            className=""
            draggable="false"
          />
        </div>
      </div>
      <ContactComponent />
    </div>
  );
}

export default AboutPageContent;
