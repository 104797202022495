import React from "react";
import styled, { keyframes } from "styled-components";

import ISO_ICON from "../../media/iso.png";
import MSME_ICON from "../../media/msme.png";
import ICSI_ICON from "../../media/icsi.png";
import ASSOCHAM_ICON from "../../media/assocham.png";
import STARTUPINDIA_ICON from "../../media/startup-india.png";
import PHD_ICON from "../../media/PHD_ICON.png";
import CII_ICON from "../../media/CII_ICON.png"

const recognition = [
  ISO_ICON,
  MSME_ICON,
  ICSI_ICON,
  STARTUPINDIA_ICON,
  ASSOCHAM_ICON,
  PHD_ICON,
  CII_ICON,
  ISO_ICON,
  MSME_ICON,
  ICSI_ICON,
  STARTUPINDIA_ICON,
  ASSOCHAM_ICON,
  PHD_ICON,
  CII_ICON
];

// Keyframes for the animation
const scroll = keyframes`
    0% { transform: translateX(0); }
    50% { transform: translateX(-262px); }
    100% { transform: translateX(-525px); }
`;

// Styled components
const SliderContainer = styled.div`
  align-items: center;
  display: flex;
  height: 200px;
  max-width: 900px;
  margin: auto;
  overflow-x: hidden;
`;

const Slider = styled.div`
  display: flex;
  animation: ${scroll} 10s linear infinite;
  width: calc(1800px);
  gap: 2rem;
`;

const Slide = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

// React component
const SliderComponent = () => {
  // Double the images to ensure seamless looping
  const images = [...recognition];

  return (
    <SliderContainer>
      <Slider>
        {images.map((item, index) => (
          <Slide key={index}>
            <Image src={item} alt="Recognition" />
          </Slide>
        ))}
      </Slider>
    </SliderContainer>
  );
};

export default SliderComponent;
