import React from "react";
import "./CtaComponent.css";

function CtaComponent(props) {
  const { scrollVal, scrollDuration } = props;
  let windowWidth = window.innerWidth;
  const ScrollDown = () => {
    if(windowWidth < 500){
      let val = scrollVal - window.scrollY;
      setTimeout(() => {
        window.scrollBy({top: val, letf: 0, behavior: "smooth"});
        // console.log('val',val);
      }, scrollDuration); 
    }else{
      let val = scrollVal - window.scrollY;
      setTimeout(() => {
        window.scrollBy({ top: val, letf: 0, behavior: "smooth" });
        // console.log(val);
      }, scrollDuration); 
    }
  };

  return (
    <div className="main_cta_component" data-aos="fade-up">
      <p className="cta_component_text">{props.description}</p>
        <button onClick={ScrollDown}>
          {props.btnText ? props.btnText : "Request a Demo"}
        </button>
    </div>
  );
}

export default CtaComponent;
