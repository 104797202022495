import React from 'react'
import './FullWidthCard.css'
function FullWidthCard({heading, subheading, img}) {
  return (
    <section className="crm_lead_management full_card">
      <div className="upper">
        <h1 className="e2e_lead text-center font-bold text-2xl font-oswald">
         {heading}
        </h1>
        <p className='text-center mx-4 w-100 font-montserrat'>
          {subheading}
        </p>
      </div>
      <img
        data-aos="zoom-in"
        src={img}
        alt="img"
        className="e2elms"
      />{" "}
    </section>
  );
}

export default FullWidthCard