import React, { Children, useEffect } from "react";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import router from "./routes/route";
import { ScrollRestoration } from "react-router-dom";

const helmetContext = {};

function App() {
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetProvider context={helmetContext}>
        <RouterProvider router={router}>
          <ScrollRestoration />
        </RouterProvider>
      </HelmetProvider>
      <ToastContainer
        position="top-right" // Set the position of the notifications
        autoClose={3000} // Set the auto-close duration (3 seconds)
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
        progressClassName="toast-progress"
        zIndex={1000} // Set the desired z-index value
      />
    </>
  );
}

export default App;
