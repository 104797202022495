import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import seo1 from "../../media/seo1.png";
import hrms1 from "../../media/hrms1.png";
import google1 from "../../media/google1.png";
import web1 from "../../media/web-dev-1.png";
import meta1 from "../../media/meta-1.png";
import arrowIcon from "../../media/arrow-icon.svg";
import fintech from "../../media/fintech-slide.png";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        background: "transparent",
        width: "4rem",
        height: "4rem",
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "100%",
        marginRight: "-3rem",
      }}
      onClick={onClick}
    >
      <div className="group border border-[#ddd] bg-[transparent] flex items-center justify-center w-10 h-10 rounded-[100%]">
        <img
          src={arrowIcon}
          alt="arrow"
          className="overflow z-10 group-hover:-mr-7 transition-all duration-500"
        />
      </div>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        background: "transparent",
        width: "4rem",
        height: "4rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "100%",
        marginLeft: "-3rem",
      }}
      onClick={onClick}
    >
      <div className=" group border border-[#ddd] bg-[transparent] flex items-center justify-center w-10 h-10 rounded-[100%]">
        <img
          src={arrowIcon}
          alt="arrow"
          className="overflow rotate-180 z-10 group-hover:-ml-7 transition-all duration-500"
        />
      </div>
    </div>
  );
}

const SlickCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: " ", // for outer div
    dotsClass: "slick-dots", // for bottom dots
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div
      className="bg-contain overflow-hidden bg-no-repeat bg-center h-[500px] lg:h-[800px] flex justify-center items-center"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}images/our_services_background.svg)`,
      }}
    >
      <div className="wrapper w-full text-center p-[2.5rem] lg:p-0">
        <h2 className=" font-oswald text-black font-semibold text-3xl">
          Our Experties
        </h2>
        <p className="text-xl">We are commited to our core attribute</p>
        <div className="carousel w-5/6 mx-auto gap-3">
          <Slider {...settings}>
            <div className="outline-none">
              <a href="/fintech-crm" className="outline-none">
                <img
                  src={fintech}
                  className="rounded-2xl flex m-auto"
                  alt="Slide 1"
                />
              </a>
              <p className="mt-3 text-center m-auto font-bold font-oswald">
                FINTECH
              </p>
            </div>
            <div className="outline-none">
              <a href="/search-engine-optimizaion" className="outline-none">
                <img
                  src={seo1}
                  className="rounded-2xl flex m-auto"
                  alt="Slide 1"
                />
              </a>
              <p className="mt-3 text-center m-auto font-bold font-oswald">
                SEO
              </p>
            </div>
            <div className="outline-none w-100 flex-col justify-center m-auto">
              <a href="/hrms" className="outline-none">
                <img
                  src={hrms1}
                  className="rounded-2xl flex m-auto"
                  alt="Slide 2"
                />
              </a>
              <p className="mt-3 text-center m-auto font-bold  font-oswald">
                HRMS
              </p>
            </div>
            <div className="outline-none w-100 flex-col justify-center m-auto">
              <a href="/google-ads-services" className="outline-none">
                <img
                  src={google1}
                  className="rounded-2xl flex m-auto"
                  alt="Slide 3"
                />
              </a>
              <p className="mt-3 text-center m-auto font-bold  font-oswald">
                Google Ads
              </p>
            </div>
            <div className="outline-none w-100 flex-col justify-center m-auto">
              <a href="/social-media-marketing" className="outline-none">
                <img
                  src={meta1}
                  className="rounded-2xl flex m-auto"
                  alt="Slide 3"
                />
              </a>
              <p className="mt-3 text-center m-auto font-bold  font-oswald">
                Meta
              </p>
            </div>
            <div className="outline-none w-100 flex-col justify-center m-auto">
              <a href="/web-development" className="outline-none">
                <img
                  src={web1}
                  className="rounded-2xl flex m-auto"
                  alt="Slide 3"
                />
              </a>
              <p className="mt-3 text-center m-auto font-bold  font-oswald">
                Web Development
              </p>
            </div>
            {/* <div>
              <img src="https://picsum.photos/300/200?random=4" alt="Slide 4" />
            </div>
            <div>
              <img src="https://picsum.photos/300/200?random=5" alt="Slide 5" />
            </div>
            <div>
              <img src="https://picsum.photos/300/200?random=6" alt="Slide 6" />
            </div> */}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default SlickCarousel;
