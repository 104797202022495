import React, { useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./ContactPageContent.css";
import Contact_us_hero from "../../media/Contact_us_hero.webp";
import WaveIcon from "../../media/wave.svg";
import SectionCard from "../CommonComponents/SectionCard";
import PHONE_ICON from "../../media/phone_icon.png";
import CHAT_ICON from "../../media/chat_icon.png";
import EMAIL_ICON from "../../media/email@icon.png";
import MAP_ICON from "../../media/map_icon.png";
import FAQ_IMAGE from "../../media/contact_us_faq.webp";
import CONVERSION_STEPS from "../../media/Conversion-Steps.webp";
import ContactComponent from "../CommonComponents/ContactComponent/ContactComponent";
gsap.registerPlugin(ScrollTrigger);

function ContactPageContent({ test }) {
  // for animation
  useEffect(() => {
    gsap.from(".ServiceCardFromBottom", {
      scrollTrigger: {
        trigger: ".ServiceCardFromBottom",
        scrub: false,
        // markers: true,
        start: "top 90%",
        end: "bottom -200%",
        toggleActions: "play reverse play reverse",
      },
      y: 100,
      rotate: -40,
      opacity: 0,
    });
    gsap.to(".ServiceCardFromBottom", {
      scrollTrigger: {
        trigger: ".ServiceCardFromBottom",
        scrub: false,
        // markers: true,
        start: "top 90%",
        end: "bottom -200%",
        toggleActions: "play reverse play reverse",
      },
      y: 0,
      rotate: 0,
      opacity: 1,
      duration: 1,
    });
  }, []);
  // faq logic
  const [faq1, setFaq1] = useState(true);
  const handleFaqClick1 = () => {
    setFaq1((prev) => !prev);
    setFaq2(false);
    setFaq3(false);
    setFaq4(false);
    setFaq5(false);
    setFaq6(false);
  };
  const [faq2, setFaq2] = useState(false);
  const handleFaqClick2 = () => {
    setFaq1(false);
    setFaq2((prev) => !prev);
    setFaq3(false);
    setFaq4(false);
    setFaq5(false);
    setFaq6(false);
  };
  const [faq3, setFaq3] = useState(false);
  const handleFaqClick3 = () => {
    setFaq1(false);
    setFaq2(false);
    setFaq3((prev) => !prev);
    setFaq4(false);
    setFaq5(false);
    setFaq6(false);
  };
  const [faq4, setFaq4] = useState(false);
  const handleFaqClick4 = () => {
    setFaq1(false);
    setFaq2(false);
    setFaq3(false);
    setFaq4((prev) => !prev);
    setFaq5(false);
    setFaq6(false);
  };
  const [faq5, setFaq5] = useState(false);
  const handleFaqClick5 = () => {
    setFaq1(false);
    setFaq2(false);
    setFaq3(false);
    setFaq4(false);
    setFaq5((prev) => !prev);
    setFaq6(false);
  };
  const [faq6, setFaq6] = useState(false);
  const handleFaqClick6 = () => {
    setFaq1(false);
    setFaq2(false);
    setFaq3(false);
    setFaq4(false);
    setFaq5(false);
    setFaq6((prev) => !prev);
  };
  return (
    <div className="contact_page_content">
      {/* Hero  */}
      <div className="contact_page_content_hero bg-transparent">
        <div className="contact_page_content_left" data-aos="fade-right">
          <div className="contact_page_content_left_subtitle font-oswald">
            CONTACT US
          </div>
          <h2>Converting Idea's Into Business</h2>
          <div className="contact_page_content_left_heading font-oswald"></div>
          <div className="business_conversion">
            <img src={CONVERSION_STEPS} alt="img" draggable="false" />
          </div>
        </div>
        <div className="contact_page_content_right" data-aos="fade-left">
          <img src={Contact_us_hero} alt="About_Us_Hero" draggable="false" />
        </div>
      </div>
      {/* AboutPage Content Started */}
      {/* Contact Option Started  */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-auto"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20 "}
        visible={test ? "hidden" : "show"}
      >
        <div className="otherContactOptions">
          <div className="otherContactOptions_top">
            <img src={WaveIcon} alt="icon" className="w-16" />
            <h2 className="font-oswald">Contact Our Friendly Team</h2>
            <p>Let us know how we can help</p>
          </div>
          <div className="contact_cards ">
            <div className="contact_cards_item contact_cards_item1 ">
              <img
                src={EMAIL_ICON}
                alt="contact_icon"
                className="w-7"
                data-aos="fade-down-right"
              />
              <h3 className="font-oswald">Chat to sales</h3>
              <h4>Speak to our friendly team.</h4>
              <a href="mailto:reachus@nextbigbox.io">reachus@nextbigbox.io</a>
            </div>
            <div className="contact_cards_item contact_cards_item2 ">
              <img
                src={CHAT_ICON}
                alt="contact_icon"
                className="w-7"
                data-aos="fade-up"
              />
              <h3 className="font-oswald">Chat to support</h3>
              <h4>We are here to help.</h4>
              <a href="mailto:reachus@nextbigbox.io">reachus@nextbigbox.io</a>
            </div>
            {/* <div className="contact_cards_item contact_cards_item3 ">
              <img
                src={MAP_ICON}
                alt="contact_icon"
                className="w-7"
                data-aos="fade-up"
              />
              <h3 className="font-oswald">Visit us</h3>
              <h4>Visit our office.</h4>
              <a
                href="https://www.google.com/maps/place/Nextbigbox+-+Best+Digital+marketing+agency+in+delhi/@28.6460563,76.979492,11z/data=!3m1!5s0x390d0353e9abdbf9:0xbd7a4014b66f509e!4m10!1m2!2m1!1snextbibox!3m6!1s0x390ce3344f42b243:0xe826e296acc25cfc!8m2!3d28.6116607!4d770935728!15sCgluZXh0Ymlib3haCyIJbmV4dGJpYm94kgEaaW50ZXJuZXRfbWFya2V0aW5nX3NlcnZpY2WaASRDaGREU1VoTk1HOW5TMFZKUTBGblNVTnROalZQYURCM1JSQULgAQA!16s%2Fg%2F11pkwndz7y?entry=ttu"
                target="_blank"
                rel="noreferrer"
              >
                View on Google Map
              </a>
            </div> */}
            <div className="contact_cards_item contact_cards_item4 ">
              <img
                src={PHONE_ICON}
                alt="contact_icon"
                className="w-7"
                data-aos="fade-down-left"
              />
              <h3 className="font-oswald">Chat with us</h3>
              <h4>Mon to Friday 10:00 Am to 6:00 PM. IST</h4>
              <a href="https://join.skype.com/invite/vtlQoo4oa5ox" target="blank">skype: Nextbigbox Pvt. Ltd. </a>
            </div>
          </div>
        </div>
      </SectionCard>
      {/* Contact Option End  */}
      {/* Faq's Section Started  */}
      <div className="faq_section">
        <div>
          <h1>FAQ'S</h1>
        </div>
        <div className="faq_section_detail">
          <div className="faq_section_left" data-aos="zoom-out-right">
            <h3 id="mytext font-oswald">STILL HAVE QUESTION</h3>
            <p>Relax Because we always will be here for you</p>
            <img
              src={FAQ_IMAGE}
              className="contact_faq"
              alt="faq_image"
              draggable="false"
            />
          </div>
          <div className="faq_section_right" data-aos="zoom-out-left">
            <ul>
              <li className="FaqFromLeft">
                <div
                  onClick={handleFaqClick1}
                  className="cursor-pointer transition-transform transform"
                >
                  <h2>1. How involved will I be in the improvement process?</h2>{" "}
                  <span
                    className={`${
                      faq1 ? "rotate-45" : ""
                    } inline-block transition-transform transform`}
                  >
                    +
                  </span>
                </div>
                <div
                  className={`${
                    !faq1
                      ? "max-h-0 opacity-0 overflow-hidden h-0"
                      : "max-h-screen opacity-100"
                  } transition-max-h transition-opacity faq_description `}
                >
                  We believe in collaborative development and will hold you
                  engaged during the undertaking. We'll offer ordinary updates,
                  seek feedback at key milestones, and include your insights to
                  ensure the final product aligns with your vision.
                </div>
              </li>
              <li className="FaqFromLeft">
                <div
                  onClick={handleFaqClick2}
                  className="cursor-pointer transition-transform transform"
                >
                  <h2>2. How long does the front-end development take?</h2>{" "}
                  <span
                    className={`${
                      faq2 ? "rotate-45" : ""
                    } inline-block transition-transform transform`}
                  >
                    +
                  </span>
                </div>
                <div
                  className={`${
                    !faq2
                      ? "max-h-0 opacity-0 overflow-hidden"
                      : "max-h-screen opacity-100"
                  } transition-max-h transition-opacity faq_description `}
                >
                  The timeline relies upon the complexity of your undertaking,
                  your particular requirements, and the functions you need to
                  include. We'll provide an in-depth task timeline during the
                  initial session phase.
                </div>
              </li>
              <li className="FaqFromLeft">
                <div
                  onClick={handleFaqClick3}
                  className="cursor-pointer transition-transform transform"
                >
                  <h2>3. What takes place after the website is released?</h2>{" "}
                  <span
                    className={`${
                      faq3 ? "rotate-45" : ""
                    } inline-block transition-transform transform`}
                  >
                    +
                  </span>
                </div>
                <div
                  className={`${
                    !faq3
                      ? "max-h-0 opacity-0 overflow-hidden"
                      : "max-h-screen opacity-100"
                  } transition-max-h transition-opacity faq_description`}
                >
                  Our assistance would not stop with the release. We offer
                  submit-release support to cope with any troubles that may
                  arise and provide help in coping with updates, upgrades, and
                  ongoing renovation.
                </div>
              </li>
              <li className="FaqFromLeft">
                <div
                  onClick={handleFaqClick4}
                  className="cursor-pointer transition-transform transform"
                >
                  <h2>
                    4. Can you work with a present layout or redecorate and
                    present a website?
                  </h2>{" "}
                  <span
                    className={`${
                      faq4 ? "rotate-45" : ""
                    } inline-block transition-transform transform`}
                  >
                    +
                  </span>
                </div>
                <div
                  className={`${
                    !faq4
                      ? "max-h-0 opacity-0 overflow-hidden"
                      : "max-h-screen opacity-100"
                  } transition-max-h transition-opacity faq_description`}
                >
                  Absolutely. We can collaborate with your current design crew
                  to bring their vision to existence, or we can redesign your
                  current internet site to improve its consumer revel in,
                  overall performance, and modernity.
                </div>
              </li>
              <li className="FaqFromLeft">
                <div
                  onClick={handleFaqClick5}
                  className="cursor-pointer transition-transform transform"
                >
                  <h2>
                    5. We can help hire your resources to grow your business
                  </h2>{" "}
                  <span
                    className={`${
                      faq5 ? "rotate-45" : ""
                    } inline-block transition-transform transform`}
                  >
                    +
                  </span>
                </div>
                <div
                  className={`${
                    !faq5
                      ? "max-h-0 opacity-0 overflow-hidden"
                      : "max-h-screen opacity-100"
                  } transition-max-h transition-opacity faq_description`}
                >
                  We can help you hire the right resources who are experienced
                  in handling similar businesses. In this business, as in all
                  others, team experienced in similar business can give you the
                  required headstart over others.
                </div>
              </li>
              <li className="FaqFromLeft">
                <div
                  onClick={handleFaqClick6}
                  className="cursor-pointer transition-transform transform"
                >
                  <h2>6. We can give you the required marketing support</h2>{" "}
                  <span
                    className={`${
                      faq6 ? "rotate-45" : ""
                    } inline-block transition-transform transform duration-1000 ease-in-out`}
                  >
                    +
                  </span>
                </div>
                <div
                  className={`${
                    !faq6
                      ? "max-h-0 opacity-0 overflow-hidden faq_description"
                      : "max-h-screen opacity-100"
                  } transition-max-h transition-opacity duration-1000 ease-in-out`}
                >
                  We have a large team of marketing professionals who will carry
                  out your marketing. Marketing is the essence of this business
                  as all customers are sourced from digital marketing.
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ContactComponent />
    </div>
  );
}

export default ContactPageContent;
