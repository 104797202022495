import React from 'react'
import './Footer.css'
function Footer() {
  return (
    <div className="main_coming_soon_footer">
      <div className="copyright">
        All rights are reserved &copy; copyright 2023 Powered by{" "}
        <a href="https://www.nextbigbox.io/" target="blank">
          Nextbigbox
        </a>
      </div>
    </div>
  );
}

export default Footer