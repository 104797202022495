import React from "react";
import "./ReviewComponent.css";
import startIcon from "../../../media/star-icon.png";
import { Truck } from "react-bootstrap-icons";

function ReviewComponent() {
  function truncateWords(text) {
    let maxLength = 230;
    if (text.length > maxLength) {
      var truncatedText = text.substr(0, maxLength);
      var lastSpaceIndex = truncatedText.lastIndexOf(" ");
      if (lastSpaceIndex !== -1) {
        truncatedText = truncatedText.substr(0, lastSpaceIndex);
      }
      truncatedText += "...";
      return truncatedText;
    } else {
      return text;
    }
  }

  return (
    <div className="overflow-hidden mx-6 lg:mx-32">
      <div className="container lg:my-0rounded-2xl p-3 flex gap-2">
        <div className="w-[200px] h-[200px] border bg-[#ddd] rounded-md">
          <div className="flex gap-3 p-2">
            <div className="w-7 h-7 bg-blue-900 items-center text-center text-white rounded-[100%]">
              K
            </div>
            <div className="">
              <p className="text-[.6rem] font-semibold font-oswald ">Kureshi Irshad</p>
              <p className="text-[.5rem]">1 review</p>
            </div>
          </div>
          <div className="flex items-center gap-2 px-2">
            <ul className="flex">
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
            </ul>
            <p className="text-[.7rem]">few weeks ago</p>
          </div>
          <div className="text-[.7rem] p-2 text-left pb-2 font-montserrat">
            {" "}
            {truncateWords(
              "I was completely impressed with their professionalism and customer service. Their staff is not only friendly but also highly skilled. definitely value for money."
            )}
          </div>
        </div>
        <div className="w-[200px] h-[200px] border bg-[#ddd] rounded-md">
          <div className="flex gap-3 p-2">
            <div className="w-7 h-7 bg-blue-900 items-center text-center text-white rounded-[100%]">
              A
            </div>
            <div className="">
              <p className="text-[.6rem] font-semibold font-oswald">Azurabathware</p>
              <p className="text-[.5rem]">1 review</p>
            </div>
          </div>
          <div className="flex items-center gap-2 px-2">
            <ul className="flex">
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
            </ul>
            <p className="text-[.7rem]">few weeks ago</p>
          </div>
          <div className="text-[.7rem] p-2 text-left pb-2 font-montserrat">
            {truncateWords(
              "This is a very nice Company , i am impressed by the work of next big box , the front end developer ( Amit Gupta ) is also very quick and responsive at the time , I got my points very quick and easily ."
            )}
          </div>
        </div>
        <div className="w-[200px] h-[200px] border bg-[#ddd] rounded-md">
          <div className="flex gap-3 p-2">
            <div className="w-7 h-7 bg-blue-900 items-center text-center text-white rounded-[100%]">
              A
            </div>
            <div className="">
              <p className="text-[.6rem] font-semibold font-oswald">Akshay Narang</p>
              <p className="text-[.5rem]">1 review</p>
            </div>
          </div>
          <div className="flex items-center gap-2 px-2">
            <ul className="flex">
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
            </ul>
            <p className="text-[.7rem]">few weeks ago</p>
          </div>
          <div className="text-[.7rem] p-2 text-left pb-2 font-montserrat">
            {truncateWords(
              "Their work & strategies are giving great results & response to my website. Really satisfied with the results. Thanks to Aarti & team."
            )}
          </div>
        </div>
        <div className="w-[200px] h-[200px] border bg-[#ddd] rounded-md">
          <div className="flex gap-3 p-2">
            <div className="w-7 h-7 bg-blue-900 items-center text-center text-white rounded-[100%]">
              S
            </div>
            <div className="">
              <p className="text-[.6rem] font-semibold font-oswald">Satish Gosavi</p>
              <p className="text-[.5rem]">1 review</p>
            </div>
          </div>
          <div className="flex items-center gap-2 px-2">
            <ul className="flex">
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
            </ul>
            <p className="text-[.7rem]">few weeks ago</p>
          </div>
          <div className="text-[.7rem] p-2 text-left pb-2 font-montserrat">
            {" "}
            {truncateWords(
              "I am using the services of Nextbigbox for last 2 years now. They are always attentive for all queries. I will recommend the services of Nextbigbox for SEO, PPC, Digital Marketing all these services I have used myself and can vouch for the same."
            )}
          </div>
        </div>
        <div className="w-[200px] h-[200px] border bg-[#ddd] rounded-md">
          <div className="flex gap-3 p-2">
            <div className="w-7 h-7 bg-blue-900 items-center text-center text-white rounded-[100%]">
              S
            </div>
            <div className="">
              <p className="text-[.6rem] font-semibold font-oswald">Sanil Rao</p>
              <p className="text-[.5rem]">1 review</p>
            </div>
          </div>
          <div className="flex items-center gap-2 px-2">
            <ul className="flex">
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
            </ul>
            <p className="text-[.7rem]">few weeks ago</p>
          </div>
          <div className="text-[.7rem] p-2 text-left pb-2 font-montserrat">
            {truncateWords(
              "Extremely delighted by the overall effort of Hitesh and Sucheta. Together they have ensured the average cost per lead is reduced and total number of leads have increased. Both were very prompt and accommodating across all the request."
            )}
          </div>
        </div>
        <div className="w-[200px] h-[200px] border bg-[#ddd] rounded-md">
          <div className="flex gap-3 p-2">
            <div className="w-7 h-7 bg-blue-900 items-center text-center text-white rounded-[100%]">
              A
            </div>
            <div className="">
              <p className="text-[.6rem] font-semibold font-oswald">Anisha Arvind</p>
              <p className="text-[.5rem]">1 review</p>
            </div>
          </div>
          <div className="flex items-center gap-2 px-2">
            <ul className="flex">
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
            </ul>
            <p className="text-[.7rem]">few weeks ago</p>
          </div>
          <div className="text-[.7rem] p-2 text-left pb-2 font-montserrat">
            {truncateWords(
              "I m currently in touch with next big box for a website work for my small buisness. What a Brilliant work they are doing. I am totally happy with the service. Its become more easy to handle a website. Thankyou so much team NBB. Highly recommended."
            )}
          </div>
        </div>
        <div className="w-[200px] h-[200px] border bg-[#ddd] rounded-md">
          <div className="flex gap-3 p-2">
            <div className="w-7 h-7 bg-blue-900 items-center text-center text-white rounded-[100%]">
              P
            </div>
            <div className="">
              <p className="text-[.6rem] font-semibold font-oswald">Primitive Design</p>
              <p className="text-[.5rem]">1 review</p>
            </div>
          </div>
          <div className="flex items-center gap-2 px-2">
            <ul className="flex">
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
            </ul>
            <p className="text-[.7rem]">few weeks ago</p>
          </div>
          <div className="text-[.7rem] p-2 text-left pb-2 font-montserrat">
            {truncateWords(
              "I’m very pleased to write a few encouraging words about the service offered by Nextbigbox. We have worked with many so called “industry experts” before, but never achieved the results we were after. We heard about Nextbigbox SEO and after realising they were located just down the road from our business, we decided to put our trust into them and after working with the company for a short time we were already seeing the results we were looking for."
            )}
          </div>
        </div>
        <div className="w-[200px] h-[200px] border bg-[#ddd] rounded-md">
          <div className="flex gap-3 p-2">
            <div className="w-7 h-7 bg-blue-900 items-center text-center text-white rounded-[100%]">
              N
            </div>
            <div className="">
              <p className="text-[.6rem] font-semibold font-oswald">Neha Kumari</p>
              <p className="text-[.5rem]">1 review</p>
            </div>
          </div>
          <div className="flex items-center gap-2 px-2">
            <ul className="flex">
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
              <li>
                <img src={startIcon} className="w-3" alt="start-icon" />
              </li>
            </ul>
            <p className="text-[.7rem]">few weeks ago</p>
          </div>
          <div className="text-[.7rem] p-2 text-left pb-2 font-montserrat">
            {truncateWords(
              "Great digital marketing company I took the google ads services and got good results for now. So I would like to refer to all my friends and family."
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewComponent;
