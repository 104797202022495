import React from 'react'
import Progress from '../Components/coming_soon/Progress'
import Footer from '../Components/coming_soon/Footer'


function ComingSoonPage() {
  return (
    <div>
      <Progress />
      <Footer />
    </div>
  );
}

export default ComingSoonPage