import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Select, Space, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import axios from "axios";

function FooterForm() {
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    control,
    watch,
    reset,
  } = useForm();

  const [fbLeads, setFbLeads] = useState("");

  const allCheckboxes = watch([
    "webDesign",
    "socialMediaMarketing",
    "contentMarketing",
    "cro",
    "GoogleAds",
    "webDevelopment",
    "ppcAds",
    "photographyVideo",
    "emailMarketing",
    "searchEngineMarketing",
    "fintechCrm",
    "hrms",
    "linkBuilding",
    "reputationManagement",
    "webHosting",
    "Seo",
    "apiIntegration",
    "fintechSolution",
  ]);

  useEffect(() => {
    // Get the full URL
    const fullUrl = window.location.href;

    // Parse the URL and query parameters
    const url = new URL(fullUrl);
    const params = new URLSearchParams(url.search);

    // Get the 'utm_source' parameter
    const utmSource = params.get("utm_source");

    // Determine the value for fbLeads based on utmSource
    let leadsValue = "";
    switch (utmSource) {
      case "facebook_organic":
        leadsValue = "Fb Organic";
        break;
      case "Google_CPC":
        leadsValue = "Google CPC";
        break;
      case "instagram_organic":
        leadsValue = "Instagram Organic";
        break;
      case "linkedin_organic":
        leadsValue = "Linkedin Organic";
        break;
      case "PPL_DelhiNCR":
        leadsValue = "PPL Delhi NCR";
        break;
      case "Diwali_Offer":
        leadsValue = "Diwali Offer";
        break;
      case "INSTAGRAM_PAID":
        leadsValue = "INSTAGRAM PAID";
        break;
      case "Whatsapp_API":
        leadsValue = "Whatsapp API";
        break;
      case "Bangalore_data":
        leadsValue = "W Bangalore";
        break;
      case "Phamphlet":
        leadsValue = "Phamphlet";
        break;
      case "Deman_Gen":
        leadsValue = "Demand Gen";
        break;
      case "FACEBOOK":
        leadsValue = "Fb";
        break;
      case "EMAIL":
        leadsValue = "EMAIL";
        break;
      case "SMS":
        leadsValue = "SMS";
        break;
      case "Raw_Data":
        leadsValue = "Raw Data";
        break;
      case "Whatsapp_new":
        leadsValue = "Whatsapp";
        break;
      case "Offer":
        leadsValue = "Offer";
        break;
      case "Bings_Ads":
        leadsValue = "Bings Ads";
        break;
      case "Google_PPLJAN":
        leadsValue = "Google PPLJAN";
        break;
      case "Insta_Inpaid":
        leadsValue = "Insta IN";
        break;
      case "FB_Inpaid":
        leadsValue = "FB IN";
        break;
      case "Whatsapp_In":
        leadsValue = "Whatsapp IN";
        break;
      case "Email_In":
        leadsValue = "Email IN";
        break;
      case "SMS_In":
        leadsValue = "SMS IN";
        break;
      default:
        leadsValue = "nextbigbox.io";
    }
    // Set the fbLeads state
    setFbLeads(leadsValue);
  }, []);

  const onSubmit = async (data) => {
    // Seperating the services from data which has been only selected true.
    const allServices = Object.keys(data)
      .filter((key) => data[key] === true)
      .join(", ");

    const formData = new FormData();
    formData.append("company_url", data.websiteLink);
    formData.append("uname", data.name);
    formData.append("email", data.email);
    formData.append("mobile", Number(data.mobile));
    formData.append("utmSource", fbLeads);
    formData.append("servicesType", allServices);
    formData.append("remarks", data.message);
    console.log(allServices);

    await axios
      .post(
        "https://salescrm.nextbigbox.tech/api/addNewWebsiteLead",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((e) => {
        showToast("success");
      })
      .catch((e) => {
        showToast("error");
      });

    reset();
  };

  const showToast = (status) => {
    switch (status) {
      case "success":
        toast.success("Form Submitted Successfully.", {
          position: "top-right",
          autoClose: 1000, // Close the toast after 1 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        break;
      case "error":
        toast.error("Something Went Wrong.", {
          position: "top-right",
          autoClose: 1000, // Close the toast after 1 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        break;
      default:
    }
  };

  return (
    <div>
      <h3 className="mt-24 font-bold text-[2.4rem] text-center">
        Get My FREE Digital Marketing Proposal
      </h3>
      <div className="mt-6 p-6 overflow-hidden mb-10 lg:mb-28">
        <div className="w-full lg:w-[80%] rounded-xl p-5 md:p-10 bg-[#EDF0F5] mx-auto max-w-[1155px]">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-1/3 flex flex-col items-start p-2">
                <label className="font-semibold text-lg mb-1" htmlFor="name">
                  Name *
                </label>
                <input
                  type="text"
                  className="w-[100%] h-[3rem] rounded-lg p-2 "
                  placeholder="Enter your name here"
                  name="name"
                  id="name"
                  {...register("name", { required: "Name is required" })}
                  aria-invalid={errors.name ? "true" : "false"}
                />
                {errors.name && (
                  <span role="alert" className="form-error">
                    {errors.name?.message}
                  </span>
                )}
              </div>
              <div className="w-full lg:w-1/3 flex flex-col items-start p-2">
                <label className="font-semibold text-lg mb-1" htmlFor="email">
                  E-mail Address *
                </label>
                <input
                  type="text"
                  id="email"
                  className="w-[100%] h-[3rem] rounded-lg p-2 "
                  placeholder="Enter the email address"
                  {...register("email", {
                    required: "Email id is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address.",
                    },
                  })}
                />
                {errors.email && (
                  <span role="alert" className="form-error">
                    {errors.email?.message}
                  </span>
                )}
              </div>
              <div className="w-full lg:w-1/3 flex flex-col items-start p-2">
                <label className="font-semibold text-lg mb-1" htmlFor="company">
                  Company/Organization *
                </label>
                <input
                  type="text"
                  id="company"
                  className="w-[100%] h-[3rem] rounded-lg p-2"
                  placeholder="Enter the Company/Organization"
                  {...register("company", {
                    required: "Company name is required",
                  })}
                />
                {errors.company && (
                  <span role="alert" className="form-error">
                    {errors.company?.message}
                  </span>
                )}
              </div>
              <div className="w-full lg:w-1/3 flex flex-col items-start p-2">
                <label
                  className="font-semibold text-lg mb-1"
                  htmlFor="websiteLink"
                >
                  Website *
                </label>
                <input
                  type="text"
                  id="websiteLink"
                  className="w-[100%] h-[3rem] rounded-lg p-2 "
                  placeholder="Enter the Link of your Website"
                  {...register("websiteLink", { required: "Link is required" })}
                />
                {errors.websiteLink && (
                  <span role="alert" className="form-error">
                    {errors.websiteLink?.message}
                  </span>
                )}
              </div>
              <div className="w-full lg:w-1/3 flex flex-col items-start p-2">
                <label className="font-semibold text-lg mb-1" htmlFor="mobile">
                  Mobile No *
                </label>
                <input
                  type="text"
                  id="mobile"
                  className="w-[100%] h-[3rem] rounded-lg p-2 "
                  placeholder="Enter the email address"
                  {...register("mobile", {
                    required: "Mobile No is required",
                    minLength: {
                      value: 10,
                      message: "Mobile number should be at least 10 digits",
                    },
                    maxLength: {
                      value: 10,
                      message: "Mobile number should be at most 10 digits",
                    },
                  })}
                />
                {errors.mobile && (
                  <span role="alert" className="form-error">
                    {errors.mobile?.message}
                  </span>
                )}
              </div>
            </div>
            <h3 className="mt-7 font-semibold text-xl mb-4">
              Which services can we provide you
            </h3>
            <div className="flex flex-wrap w-[100%] justify-between mb-7">
              <ul>
                <li>
                  <div className="m-2">
                    <input
                      type="checkbox"
                      id="webDesign"
                      {...register("webDesign", {
                        validate: () =>
                          allCheckboxes.some((val) => val) ||
                          "Please select at least one service",
                      })}
                    />
                    <label htmlFor="webDesign" className="ml-2">
                      Web Design
                    </label>
                  </div>
                </li>
                <li>
                  <div className="m-2">
                    <input
                      type="checkbox"
                      id="socialMediaMarketing"
                      {...register("socialMediaMarketing")}
                    />
                    <label htmlFor="socialMediaMarketing" className="ml-2">
                      Social Media Marketing
                    </label>
                  </div>
                </li>
                <li>
                  <div className="m-2">
                    <input
                      type="checkbox"
                      id="contentMarketing"
                      {...register("contentMarketing")}
                    />
                    <label htmlFor="contentMarketing" className="ml-2">
                      Content Marketing
                    </label>
                  </div>
                </li>
                <li>
                  <div className="m-2">
                    <input type="checkbox" id="cro" {...register("cro")} />
                    <label htmlFor="cro" className="ml-2">
                      Conversion Rate Optimization (CRO)
                    </label>
                  </div>
                </li>
                <div className="m-2">
                  <input
                    type="checkbox"
                    id="googleAds"
                    {...register("GoogleAds")}
                  />
                  <label htmlFor="googleAds" className="ml-2">
                    Google Ads
                  </label>
                </div>
                <div className="m-2">
                  <input
                    type="checkbox"
                    id="webDevelopment"
                    {...register("webDevelopment")}
                  />
                  <label htmlFor="webDevelopment" className="ml-2">
                    Web Development
                  </label>
                </div>
              </ul>

              <ul>
                <li>
                  <div className="m-2">
                    <input
                      type="checkbox"
                      id="ppcAds"
                      {...register("ppcAds")}
                    />
                    <label htmlFor="ppcAds" className="ml-2">
                      PPC Ads
                    </label>
                  </div>
                </li>
                <li>
                  <div className="m-2">
                    <input
                      type="checkbox"
                      id="photographyVideo"
                      {...register("photographyVideo")}
                    />
                    <label htmlFor="photographyVideo" className="ml-2">
                      Photography / Video
                    </label>
                  </div>
                </li>
                <li>
                  <div className="m-2">
                    <input
                      type="checkbox"
                      id="emailMarketing"
                      {...register("emailMarketing")}
                    />
                    <label htmlFor="emailMarketing" className="ml-2">
                      Email Marketing
                    </label>
                  </div>
                </li>
                <li>
                  <div className="m-2">
                    <input
                      type="checkbox"
                      id="searchEngineMarketing"
                      {...register("searchEngineMarketing")}
                    />
                    <label htmlFor="searchEngineMarketing" className="ml-2">
                      Search Engine Marketing
                    </label>
                  </div>
                </li>
                <div className="m-2">
                  <input
                    type="checkbox"
                    id="fintechCrm"
                    {...register("fintechCrm")}
                  />
                  <label htmlFor="fintechCrm" className="ml-2">
                    Fintech Crm
                  </label>
                </div>
                <div className="m-2">
                  <input type="checkbox" id="hrms" {...register("hrms")} />
                  <label htmlFor="hrms" className="ml-2">
                    HRMS
                  </label>
                </div>
              </ul>

              <ul>
                <li>
                  <div className="m-2">
                    <input
                      type="checkbox"
                      id="linkBuilding"
                      {...register("linkBuilding")}
                    />
                    <label htmlFor="linkBuilding" className="ml-2">
                      Link Building
                    </label>
                  </div>
                </li>
                <li>
                  <div className="m-2">
                    <input
                      type="checkbox"
                      id="reputationManagement"
                      {...register("reputationManagement")}
                    />
                    <label htmlFor="reputationManagement" className="ml-2">
                      Reputation / Reviews Management
                    </label>
                  </div>
                </li>
                <li>
                  <div className="m-2">
                    <input
                      type="checkbox"
                      id="webHosting"
                      {...register("webHosting")}
                    />
                    <label htmlFor="webHosting" className="ml-2">
                      Web Hosting / Maintenance
                    </label>
                  </div>
                </li>
                <li>
                  <div className="m-2">
                    <input type="checkbox" id="seo" {...register("Seo")} />
                    <label htmlFor="seo" className="ml-2">
                      Search Engine Optimization (SEO)
                    </label>
                  </div>
                </li>
                <div className="m-2">
                  <input
                    type="checkbox"
                    id="apiIntegration"
                    {...register("apiIntegration")}
                  />
                  <label htmlFor="apiIntegration" className="ml-2">
                    API Integration
                  </label>
                </div>
                <div className="m-2">
                  <input
                    type="checkbox"
                    id="fintechSolution"
                    {...register("fintechSolution")}
                  />
                  <label htmlFor="fintechSolution" className="ml-2">
                    Fintech Solutions
                  </label>
                </div>
              </ul>
              {errors.webDesign && (
                <span role="alert" className="form-error">
                  Please select any one service
                </span>
              )}
            </div>

            <label className="mt-10 font-semibold text-xl" htmlFor="message">
              Message
            </label>
            <div>
              <textarea
                className="w-[100%] mt-4 mb-0 border-0"
                id="message"
                name="message"
                placeholder="Enter the message here"
                {...register("message", { required: false })}
              />
            </div>

            <button
              type="submit"
              className="w-1/3 h-[3rem] bg-black text-white font-semibold text-lg rounded-lg mt-4"
            >
              {isSubmitting ? (
                <Spin
                  indicator={
                    <LoadingOutlined spin style={{ color: "white" }} />
                  }
                />
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default FooterForm;
