import React from "react";
import arrowIcon from "../../../media/arrow-icon-white.svg";

function CtaSection() {
  return (
    <div className="w-100 flex justify-center">
      <div className="pt-8 pl-12 lg:ml-8 lg:pt-20 lg:pl-28 bg-[url('/src/media/cta-background.svg')] bg-contain bg-no-repeat w-[100%] lg:w-[100%] justify-center h-[200px] lg:h-[480px]  ">
        <div className="w-[100%] lg:w-[40%] ml-0 lg:m-12">
          <p className="text-sm lg:text-5xl text-white">
            Book a Free Consultation Today
          </p>
          <a href="/contact">
            <button className="group border-2 rounded-3xl rounded-s-sm py-1 lg:py-2 mt-2 lg:mt-8 py-2rounded-xl text-[1rem] lg:text-xl text-white flex justify-center items-center w-[60%] lg:w-[60%] bg-gradient-to-r from-[#6500a5] to-[#38007b]">
              <p className="text-sm">Ready to start</p>
              <img
                src={arrowIcon}
                alt="arrow"
                className="ml-4 w-12 group-hover:-mr-4 group-hover:ml-8 transition-all duration-500"
              />
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default CtaSection;
