import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "./DropComponent.css";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function DropComponent({ countryName, list, state, toggle }) {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row gap-1 items-center">
        <ul>
          <li
            onClick={() => toggle(countryName)}
            className="hover:cursor-pointer"
          >
            {countryName}
          </li>
        </ul>
        <FontAwesomeIcon
          icon={faCaretUp}
          style={{ color: "#909192" }}
          className={`transition-all duration-300 ease-in-out ${
            state[countryName]
              ? "origin-center rotate-0"
              : "origin-center rotate-180"
          }`}
        />
      </div>
      <div
        className={`overflow-hidden transition-all duration-300 ease-in-out ${
          state[countryName]
            ? "max-h-[500px] w-[110px] opacity-100"
            : "max-h-0 w-[110px] opacity-0"
        }`}
      >
        <div className="h-auto p-2 rounded-md bg-white">
          <ul className="flex flex-col">
            {list.map((data, index) => (
              <Link to={data.link} key={index}>
                <li className="hover:underline p-1 font-semibold">
                  {data.placename}
                </li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
